import React, { useEffect } from 'react';

const SimpleToast = ({ message, type = 'success', onClose, duration = 3000 }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    const baseStyles = "fixed top-4 right-4 z-50 py-2 px-4 rounded-lg shadow-lg transition-opacity duration-300";
    const typeStyles = {
        success: "bg-green-600 text-white",
        error: "bg-red-600 text-white",
    };

    return (
        <div className={`${baseStyles} ${typeStyles[type]}`}>
            {message}
        </div>
    );
};

export default SimpleToast;