import React, { useState } from 'react';
import { TextField, Button, Box, Typography, FormControlLabel, Checkbox, Dialog, DialogContent } from '@mui/material';
import { login } from '../services/apiService';

const LoginForm = ({ onLoginSuccess, onClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await login(username, password);

      if (rememberMe) {
        localStorage.setItem('token', data.token);
      } else {
        sessionStorage.setItem('token', data.token);
      }

      // Send Google Tag Manager event
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'login_success',
          user_id: data.userId || username,  // если userId доступен в ответе
          method: 'credentials'
        });
      }

      onLoginSuccess(data.token);
    } catch (err) {
      setError('Неправильный логин или пароль');

      // Опционально: отслеживание неудачных попыток входа
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'login_failed',
          error_message: err.message || 'Authentication failed'
        });
      }
    }
  };

  return (
      <Dialog open={true} onClose={onClose} PaperProps={{ sx: { backgroundColor: 'white', boxShadow: 'none', borderRadius: '12px', padding: 0 } }}>
        <DialogContent sx={{ padding: 0 }}>
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 3, p: 4, backgroundColor: 'white', borderRadius: '12px', boxShadow: 'none', maxWidth: '400px', margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h5" gutterBottom>Войти</Typography>
            {error && <Typography color="error" gutterBottom>{error}</Typography>}
            <TextField label="Username" fullWidth margin="normal" value={username} onChange={(e) => setUsername(e.target.value)} required sx={{ mb: 2 }} />
            <TextField label="Password" type="password" fullWidth margin="normal" value={password} onChange={(e) => setPassword(e.target.value)} required sx={{ mb: 2 }} />
            <FormControlLabel control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} color="primary" />} label="Запомнить меня" sx={{ mb: 2 }} />
            <Button type="submit" variant="contained" fullWidth sx={{ backgroundColor: '#FF6F00', color: 'white', padding: '10px 0', borderRadius: '8px', fontWeight: 'bold', '&:hover': { backgroundColor: '#E65100' } }}>Войти</Button>
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default LoginForm;