// pages/CourseEditor.js
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';
import {
    fetchCourseDetails,
    fetchCourseCategories,
    updateCourse,
    createModule,
    updateModule,
    deleteModule,
    createLesson,
    updateLesson,
    deleteLesson
} from '../services/apiService';
import LessonEditor from '../components/courses/LessonEditor';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    Box,
    Button,
    TextField,
    Typography,
    Paper,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Alert,
    Switch,
    FormControlLabel,
    Grid,
    CircularProgress,
    Card,
    CardContent,
    Collapse
} from '@mui/material';
import {
    Plus,
    Trash2,
    Edit2,
    GripVertical,
    ChevronDown,
    ChevronRight,
    Save,
    Image as ImageIcon,
    ArrowLeft
} from 'lucide-react';

const CourseEditor = () => {
    const { courseId } = useParams();
    const navigate = useNavigate();
    const { token, userInfo } = useContext(AuthContext);

    // Состояния
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [course, setCourse] = useState(null);
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [moduleDialog, setModuleDialog] = useState(false);
    const [lessonDialog, setLessonDialog] = useState(false);
    const [selectedModule, setSelectedModule] = useState(null);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [expandedModules, setExpandedModules] = useState({});

    // Загрузка данных курса
    useEffect(() => {
        const loadCourse = async () => {
            try {
                const [courseData, categoriesData] = await Promise.all([
                    fetchCourseDetails(courseId),
                    fetchCourseCategories()
                ]);

                setCourse(courseData);
                setPreviewImage(courseData.image_url);
                setCategories(categoriesData);

                // Инициализация развернутых модулей
                const expanded = {};
                courseData.modules.forEach(module => {
                    expanded[module.id] = true;
                });
                setExpandedModules(expanded);
            } catch (err) {
                setError('Ошибка при загрузке курса');
            } finally {
                setLoading(false);
            }
        };

        loadCourse();
    }, [courseId, token]);

    // Проверка прав доступа
    useEffect(() => {
        if (!userInfo?.profile?.is_tutor) {
            navigate('/courses');
        }
    }, [userInfo, navigate]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setCourse({ ...course, image: file });
            setPreviewImage(URL.createObjectURL(file));
        }
    };

    const handleSave = async () => {
        try {
            setSaving(true);
            const formData = new FormData();

            Object.keys(course).forEach(key => {
                if (key === 'image' && typeof course[key] !== 'string') {
                    formData.append(key, course[key]);
                } else if (key !== 'image' && key !== 'modules') {
                    formData.append(key, course[key]);
                }
            });

            await updateCourse(token, courseId, formData);
            setSuccess('Курс успешно сохранен');
        } catch (err) {
            setError('Ошибка при сохранении курса');
        } finally {
            setSaving(false);
        }
    };

    const handleModuleDragEnd = async (result) => {
        if (!result.destination) return;

        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;

        if (sourceIndex === destIndex) return;

        try {
            const updatedModules = Array.from(course.modules);
            const [movedModule] = updatedModules.splice(sourceIndex, 1);
            updatedModules.splice(destIndex, 0, movedModule);

            // Обновляем порядок
            const reorderedModules = updatedModules.map((module, index) => ({
                ...module,
                order: index + 1
            }));

            // Обновляем в UI
            setCourse({
                ...course,
                modules: reorderedModules
            });

            // Сохраняем на сервере
            for (const module of reorderedModules) {
                await updateModule(token, module.id, { order: module.order });
            }
        } catch (err) {
            setError('Ошибка при изменении порядка модулей');
        }
    };

    const handleAddModule = () => {
        setSelectedModule(null);
        setModuleDialog(true);
    };

    const handleSaveModule = async (moduleData) => {
        try {
            let updatedModules;
            if (selectedModule) {
                // Обновление существующего модуля
                const updatedModule = await updateModule(token, selectedModule.id, moduleData);
                updatedModules = course.modules.map(m =>
                    m.id === selectedModule.id ? updatedModule : m
                );
            } else {
                // Создание нового модуля
                const newModule = await createModule(token, {
                    ...moduleData,
                    course: courseId,
                    order: course.modules.length + 1
                });
                updatedModules = [...course.modules, newModule];
            }

            setCourse({
                ...course,
                modules: updatedModules
            });
            setModuleDialog(false);
        } catch (err) {
            setError('Ошибка при сохранении модуля');
        }
    };

    const handleDeleteModule = async (moduleId) => {
        if (!window.confirm('Вы уверены, что хотите удалить этот модуль?')) {
            return;
        }

        try {
            await deleteModule(token, moduleId);
            setCourse({
                ...course,
                modules: course.modules.filter(m => m.id !== moduleId)
            });
        } catch (err) {
            setError('Ошибка при удалении модуля');
        }
    };


    const handleEditModule = (moduleId) => {
        const module = course.modules.find(m => m.id === moduleId);
        setSelectedModule(module);
        setModuleDialog(true);
    };

    const handleEditLesson = (moduleId, lessonId) => {
        const module = course.modules.find(m => m.id === moduleId);
        const lesson = module.lessons.find(l => l.id === lessonId);
        setSelectedModule(moduleId);
        setSelectedLesson(lesson);
        setLessonDialog(true);
    };

    const handleAddLesson = (moduleId) => {
        setSelectedModule(moduleId);
        setSelectedLesson(null);
        setLessonDialog(true);
    };

    const handleSaveLesson = async (lessonData) => {
        try {
            const moduleIndex = course.modules.findIndex(m => m.id === selectedModule);
            if (moduleIndex === -1) return;

            const updatedModules = [...course.modules];

            if (selectedLesson) {
                // Обновление существующего урока
                const updatedLesson = await updateLesson(token, selectedLesson.id, lessonData);
                const lessonIndex = updatedModules[moduleIndex].lessons.findIndex(
                    l => l.id === selectedLesson.id
                );
                updatedModules[moduleIndex].lessons[lessonIndex] = updatedLesson;
            } else {
                // Создание нового урока
                const newLesson = await createLesson(token, {
                    ...lessonData,
                    module: selectedModule,
                    order: updatedModules[moduleIndex].lessons.length + 1
                });
                updatedModules[moduleIndex].lessons.push(newLesson);
            }

            setCourse({
                ...course,
                modules: updatedModules
            });
            setLessonDialog(false);
        } catch (err) {
            setError('Ошибка при сохранении урока');
        }
    };

    const handleDeleteLesson = async (moduleId, lessonId) => {
        if (!window.confirm('Вы уверены, что хотите удалить этот урок?')) {
            return;
        }

        try {
            await deleteLesson(token, lessonId);
            setCourse({
                ...course,
                modules: course.modules.map(module => {
                    if (module.id === moduleId) {
                        return {
                            ...module,
                            lessons: module.lessons.filter(lesson => lesson.id !== lessonId)
                        };
                    }
                    return module;
                })
            });
        } catch (err) {
            setError('Ошибка при удалении урока');
        }
    };

    if (loading) {
        return (
            <Box className="flex justify-center items-center min-h-screen">
                <CircularProgress />
            </Box>
        );
    }

    if (!course) {
        return (
            <Box className="container mx-auto px-4 py-8">
                <Alert severity="error">Курс не найден</Alert>
            </Box>
        );
    }

    return (
        <Box className="container mx-auto px-4 py-8">
            <Box className="flex items-center gap-4 mb-6">
                <IconButton onClick={() => navigate('/courses')}>
                    <ArrowLeft className="w-6 h-6" />
                </IconButton>
                <Typography variant="h4" className="font-bold">
                    Редактирование курса
                </Typography>
            </Box>

            {error && (
                <Alert severity="error" className="mb-4" onClose={() => setError(null)}>
                    {error}
                </Alert>
            )}

            {success && (
                <Alert severity="success" className="mb-4" onClose={() => setSuccess(null)}>
                    {success}
                </Alert>
            )}

            <Paper className="p-6 mb-8">
                <Typography variant="h5" className="mb-6 font-bold">
                    Основная информация
                </Typography>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            label="Название курса"
                            value={course.title}
                            onChange={(e) => setCourse({...course, title: e.target.value})}
                            className="mb-4"
                        />

                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label="Описание курса"
                            value={course.description}
                            onChange={(e) => setCourse({...course, description: e.target.value})}
                            className="mb-4"
                        />

                        <FormControl fullWidth className="mb-4">
                            <InputLabel>Категория</InputLabel>
                            <Select
                                value={course.category?.id || ''}
                                onChange={(e) => setCourse({
                                    ...course,
                                    category: categories.find(c => c.id === e.target.value)
                                })}
                            >
                                {categories.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Paper className="p-4 mb-4 text-center">
                            {previewImage ? (
                                <Box className="relative">
                                    <img
                                        src={previewImage}
                                        alt="Preview"
                                        className="w-full h-48 object-cover rounded"
                                    />
                                    <IconButton
                                        className="absolute top-2 right-2"
                                        onClick={() => {
                                            setPreviewImage(null);
                                            setCourse({...course, image: null});
                                        }}
                                    >
                                        <Trash2 className="w-4 h-4" />
                                    </IconButton>
                                </Box>
                            ) : (
                                <Box
                                    className="w-full h-48 border-2 border-dashed rounded flex flex-col items-center justify-center cursor-pointer"
                                    onClick={() => document.getElementById('course-image').click()}
                                >
                                    <ImageIcon className="w-8 h-8 text-gray-400 mb-2" />
                                    <Typography color="textSecondary">
                                        Выберите обложку курса
                                    </Typography>
                                </Box>
                            )}
                            <input
                                type="file"
                                id="course-image"
                                accept="image/*"
                                className="hidden"
                                onChange={handleImageChange}
                            />
                        </Paper>

                        <TextField
                            fullWidth
                            type="number"
                            label="Цена"
                            value={course.price || 0}
                            onChange={(e) => setCourse({...course, price: e.target.value})}
                            className="mb-4"
                        />

                        <Box className="space-y-2">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={course.is_private}
                                        onChange={(e) => setCourse({...course, is_private: e.target.checked})}
                                    />
                                }
                                label="Приватный курс"
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={course.is_published}
                                        onChange={(e) => setCourse({...course, is_published: e.target.checked})}
                                    />
                                }
                                label="Опубликован"
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={course.has_paid_features}
                                        onChange={(e) => setCourse({...course, has_paid_features: e.target.checked})}
                                    />
                                }
                                label="Платные функции"
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Box className="flex justify-end">
                    <Button
                        variant="contained"
                        startIcon={<Save />}
                        onClick={handleSave}
                        disabled={saving}
                    >
                        {saving ? 'Сохранение...' : 'Сохранить изменения'}
                    </Button>
                </Box>
            </Paper>

            <Paper className="p-6 mb-8">
                <Box className="flex justify-between items-center mb-6">
                    <Typography variant="h6" className="font-bold">
                        Структура курса
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<Plus />}
                        onClick={handleAddModule}
                    >
                        Добавить модуль
                    </Button>
                </Box>

                <DragDropContext onDragEnd={handleModuleDragEnd}>
                    <Droppable droppableId="modules">
                        {(provided) => (
                            <Box
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="space-y-4"
                            >
                                {course.modules.map((module, index) => (
                                    <Draggable
                                        key={module.id}
                                        draggableId={module.id.toString()}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <Card
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                className="mb-4"
                                            >
                                                <CardContent>
                                                    <Box className="flex items-center justify-between">
                                                        <Box className="flex items-center gap-2">
                                                            <Box {...provided.dragHandleProps}>
                                                                <GripVertical className="w-5 h-5 text-gray-400" />
                                                            </Box>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    setExpandedModules({
                                                                        ...expandedModules,
                                                                        [module.id]: !expandedModules[module.id]
                                                                    });
                                                                }}
                                                            >
                                                                {expandedModules[module.id] ? (
                                                                    <ChevronDown className="w-5 h-5" />
                                                                ) : (
                                                                    <ChevronRight className="w-5 h-5" />
                                                                )}
                                                            </IconButton>
                                                            <Typography variant="h6">
                                                                {module.title}
                                                            </Typography>
                                                        </Box>
                                                        <Box className="flex items-center gap-2">
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleEditModule(module.id)}
                                                            >
                                                                <Edit2 className="w-4 h-4" />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleDeleteModule(module.id)}
                                                                className="text-red-500 hover:text-red-700"
                                                            >
                                                                <Trash2 className="w-4 h-4" />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>

                                                    <Collapse in={expandedModules[module.id]}>
                                                        <Box className="pl-8 mt-4 space-y-2">
                                                            {module.lessons?.map((lesson, lessonIndex) => (
                                                                <Paper
                                                                    key={lesson.id}
                                                                    className="p-3 border"
                                                                    variant="outlined"
                                                                >
                                                                    <Box className="flex items-center justify-between">
                                                                        <Typography>
                                                                            {lessonIndex + 1}. {lesson.title}
                                                                        </Typography>
                                                                        <Box className="flex items-center gap-1">
                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() => handleEditLesson(module.id, lesson.id)}
                                                                            >
                                                                                <Edit2 className="w-4 h-4" />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() => handleDeleteLesson(module.id, lesson.id)}
                                                                                className="text-red-500 hover:text-red-700"
                                                                            >
                                                                                <Trash2 className="w-4 h-4" />
                                                                            </IconButton>
                                                                        </Box>
                                                                    </Box>
                                                                </Paper>
                                                            ))}
                                                            <Button
                                                                startIcon={<Plus />}
                                                                onClick={() => handleAddLesson(module.id)}
                                                                variant="outlined"
                                                                fullWidth
                                                            >
                                                                Добавить урок
                                                            </Button>
                                                        </Box>
                                                    </Collapse>
                                                </CardContent>
                                            </Card>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
            </Paper>

            {/* Диалог модуля */}
            <Dialog
                open={moduleDialog}
                onClose={() => setModuleDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {selectedModule ? 'Редактировать модуль' : 'Новый модуль'}
                </DialogTitle>
                <DialogContent>
                    <Box className="pt-2 space-y-4">
                        <TextField
                            fullWidth
                            label="Название модуля"
                            value={selectedModule?.title || ''}
                            onChange={(e) => setSelectedModule({
                                ...selectedModule,
                                title: e.target.value
                            })}
                        />
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            label="Описание модуля"
                            value={selectedModule?.description || ''}
                            onChange={(e) => setSelectedModule({
                                ...selectedModule,
                                description: e.target.value
                            })}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setModuleDialog(false)}>
                        Отмена
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleSaveModule(selectedModule || { title: '', description: '' })}
                    >
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Диалог урока */}
            <Dialog
                open={lessonDialog}
                onClose={() => setLessonDialog(false)}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    {selectedLesson ? 'Редактировать урок' : 'Новый урок'}
                </DialogTitle>
                <DialogContent>
                    <LessonEditor
                        lesson={selectedLesson}
                        onSave={(lessonData) => {
                            handleSaveLesson(lessonData);
                            setLessonDialog(false);
                        }}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default CourseEditor;