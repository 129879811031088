import React, { useState } from 'react';
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Checkbox,
    Button,
    Alert,
    CircularProgress,
    Paper
} from '@mui/material';
import { CheckCircle, Timer } from 'lucide-react';
import AssignmentFeedback from './AssignmentFeedback';
import { submitAssignment } from '../../services/apiService';

const TestAssignment = ({ assignment, token, onSubmitted }) => {
    const [answers, setAnswers] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [feedback, setFeedback] = useState(null);

    const handleAnswerChange = (questionId, value, isMultiple) => {
        setAnswers(prev => ({
            ...prev,
            [questionId]: isMultiple
                ? (prev[questionId] ?
                    prev[questionId].includes(value)
                        ? prev[questionId].filter(v => v !== value)
                        : [...prev[questionId], value]
                    : [value])
                : [value]
        }));
    };

    const handleSubmit = async () => {
        setError(null);
        setLoading(true);

        try {
            // Проверяем, что на все вопросы даны ответы
            const answeredQuestions = Object.keys(answers).length;
            const totalQuestions = assignment.questions.length;

            if (answeredQuestions < totalQuestions) {
                setError(`Пожалуйста, ответьте на все вопросы (${answeredQuestions}/${totalQuestions})`);
                return;
            }

            const response = await submitAssignment(token, assignment.id, { answers });

            if (response.feedback) {
                setFeedback(response.feedback);
            }

            onSubmitted && onSubmitted();
        } catch (err) {
            setError(err.message || 'Произошла ошибка при отправке ответов');
        } finally {
            setLoading(false);
        }
    };

    if (feedback) {
        return (
            <AssignmentFeedback
                feedback={feedback}
                onRetry={() => setFeedback(null)}
                onComplete={onSubmitted}
            />
        );
    }

    return (
        <Paper elevation={0} className="p-4">
            {error && (
                <Alert
                    severity="error"
                    className="mb-4"
                    onClose={() => setError(null)}
                >
                    {error}
                </Alert>
            )}

            {assignment.time_limit && (
                <Alert
                    icon={<Timer className="h-5 w-5"/>}
                    severity="info"
                    className="mb-4"
                >
                    Ограничение времени: {assignment.time_limit} минут
                </Alert>
            )}

            <Box className="space-y-6">
                {assignment.questions.map((question, index) => (
                    <Box
                        key={question.id}
                        className="p-4 border rounded-lg bg-gray-50"
                    >
                        <Typography className="font-medium mb-3">
                            {index + 1}. {question.question_text}
                        </Typography>

                        <FormControl component="fieldset">
                            {question.question_type === 'single' ? (
                                <RadioGroup
                                    value={answers[question.id]?.[0] || ''}
                                    onChange={(e) => handleAnswerChange(
                                        question.id,
                                        Number(e.target.value),
                                        false
                                    )}
                                >
                                    {question.options.map(option => (
                                        <FormControlLabel
                                            key={option.id}
                                            value={option.id}
                                            control={<Radio />}
                                            label={option.text}
                                        />
                                    ))}
                                </RadioGroup>
                            ) : (
                                <Box className="space-y-2">
                                    {question.options.map(option => (
                                        <FormControlLabel
                                            key={option.id}
                                            control={
                                                <Checkbox
                                                    checked={answers[question.id]?.includes(option.id) || false}
                                                    onChange={() => handleAnswerChange(
                                                        question.id,
                                                        option.id,
                                                        true
                                                    )}
                                                />
                                            }
                                            label={option.text}
                                        />
                                    ))}
                                </Box>
                            )}
                        </FormControl>
                    </Box>
                ))}
            </Box>

            <Box className="mt-6 flex justify-between items-center">
                <Typography variant="body2" color="text.secondary">
                    {Object.keys(answers).length} из {assignment.questions.length} вопросов отвечено
                </Typography>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading || Object.keys(answers).length === 0}
                    startIcon={loading ? (
                        <CircularProgress size={20} color="inherit" />
                    ) : (
                        <CheckCircle className="h-5 w-5" />
                    )}
                >
                    {loading ? 'Отправка...' : 'Отправить ответы'}
                </Button>
            </Box>
        </Paper>
    );
};

export default TestAssignment;