import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    Button,
    CircularProgress,
    Paper,
    useTheme,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
    ChevronLeft,
    ChevronRight,
    BookOpen,
    CheckCircle,
    PlayCircle,
    Circle,
    Rocket,
    FileText,
    ArrowRight,
    ClipboardCheck,
} from 'lucide-react';
import { AuthContext } from '../App';
import {
    fetchLessonDetails,
    fetchCourseDetails,
    fetchLessonAssignments,
    completeLesson
} from '../services/apiService';
import AssignmentSubmissionDialog from '../components/courses/AssignmentSubmissionDialog';
import EditableTextContent from '../components/courses/EditableTextContent';
import { Tooltip } from '@mui/material';
import VideoContent from '../components/courses/VideoContent';
import ImageContent from '../components/courses/ImageContent';

const SIDEBAR_WIDTH = '280px';

const LessonPage = () => {
    const { token } = useContext(AuthContext);
    const { courseId, lessonId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const [lesson, setLesson] = useState(null);
    const [courseDetails, setCourseDetails] = useState(null);
    const [assignments, setAssignments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [completing, setCompleting] = useState(false);
    const [showSidebar, setShowSidebar] = useState(!isMobile);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [setLessonData] = useState(null);

    useEffect(() => {
        setShowSidebar(!isMobile);
    }, [isMobile]);

    useEffect(() => {
        const loadLessonData = async () => {
            if (!token || !courseId || !lessonId) return;

            setLoading(true);

            try {
                const [lessonData, courseData, assignmentsData] = await Promise.all([
                    fetchLessonDetails(token, courseId, lessonId),
                    fetchCourseDetails(courseId),
                    fetchLessonAssignments(token, lessonId)
                ]);

                setLesson(lessonData);
                setCourseDetails(courseData);
                setAssignments(assignmentsData);
            } catch (err) {
                console.error('Error loading lesson:', err);
            } finally {
                setLoading(false);
            }
        };

        loadLessonData();
    }, [token, courseId, lessonId]);

    const handleContentUpdate = (newContent) => {
        setLessonData(prev => ({
            ...prev,
            content: newContent
        }));
    };

    const allAssignmentsCompleted = assignments.every(a => a.is_completed);

    const handleNavigation = (direction) => {
        if (!courseDetails) return;

        const allLessons = courseDetails.modules.flatMap(m => m.lessons);
        const currentIndex = allLessons.findIndex(l => l.id === parseInt(lessonId));
        const newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;

        if (newIndex >= 0 && newIndex < allLessons.length) {
            navigate(`/courses/${courseId}/lessons/${allLessons[newIndex].id}`);
        }
    };

    const renderProgressIcon = (lessonItem) => {
        if (lessonItem.id === parseInt(lessonId)) {
            return <PlayCircle className="w-5 h-5 text-blue-500" />;
        }
        return lessonItem.completed ? (
            <CheckCircle className="w-5 h-5 text-green-500" />
        ) : (
            <Circle className="w-5 h-5 text-gray-400" />
        );
    };

    const handleComplete = async () => {
        if (!token || !lessonId) return;

        setCompleting(true);
        try {
            await completeLesson(token, lessonId);
            // После успешного завершения находим следующий урок
            const allLessons = courseDetails.modules.flatMap(m => m.lessons);
            const currentIndex = allLessons.findIndex(l => l.id === parseInt(lessonId));
            if (currentIndex < allLessons.length - 1) {
                navigate(`/courses/${courseId}/lessons/${allLessons[currentIndex + 1].id}`);
            } else {
                navigate(`/courses/${courseId}`);
            }
        } finally {
            setCompleting(false);
        }
    };

    if (loading) {
        return (
            <Box className="flex justify-center items-center min-h-screen">
                <CircularProgress size={48} sx={{ color: theme => theme.palette.primary.main }} />
            </Box>
        );
    }

    const sidebarContent = (
        <Box
            sx={{
                position: 'fixed',
                top: '75px',
                left: 0,
                height: 'calc(100vh - 75px)',
                width: SIDEBAR_WIDTH,
                bgcolor: 'background.paper',
                borderRight: '1px solid',
                borderColor: 'divider',
                overflowY: 'auto',
                transition: 'transform 0.3s ease',
                transform: showSidebar ? 'translateX(0)' : `translateX(-${SIDEBAR_WIDTH})`,
                zIndex: 10
            }}
        >
            <Box sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
                    <BookOpen className="w-5 h-5 text-gray-600" />
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'text.primary' }}>
                        Содержание курса
                    </Typography>
                </Box>

                <List sx={{ '& .MuiListItem-root': { p: 0 } }}>
                    {courseDetails?.modules.map((module) => (
                        <Box key={module.id} sx={{ mb: 2 }}>
                            <ListItem>
                                <ListItemText
                                    primary={module.title}
                                    primaryTypographyProps={{
                                        color: 'text.secondary',
                                        fontWeight: 500,
                                        fontSize: '0.875rem'
                                    }}
                                />
                            </ListItem>
                            <List sx={{ pl: 2 }}>
                                {module.lessons.map((lessonItem) => (
                                    <ListItemButton
                                        key={lessonItem.id}
                                        selected={lessonItem.id === parseInt(lessonId)}
                                        onClick={() => navigate(`/courses/${courseId}/lessons/${lessonItem.id}`)}
                                        sx={{
                                            borderRadius: 1,
                                            mb: 0.5,
                                            '&.Mui-selected': {
                                                bgcolor: alpha(theme.palette.primary.main, 0.08),
                                                '&:hover': {
                                                    bgcolor: alpha(theme.palette.primary.main, 0.12),
                                                }
                                            }
                                        }}
                                    >
                                        <ListItemIcon sx={{ minWidth: 32 }}>
                                            {renderProgressIcon(lessonItem)}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={lessonItem.title}
                                            primaryTypographyProps={{
                                                fontSize: '0.875rem',
                                                fontWeight: lessonItem.id === parseInt(lessonId) ? 500 : 400
                                            }}
                                        />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Box>
                    ))}
                </List>
            </Box>
        </Box>
    );

    const mainContent = (
        <Box
            sx={{
                ml: { xs: 0, lg: SIDEBAR_WIDTH },
                transition: 'margin 0.3s ease',
                width: '100%',
                minHeight: 'calc(100vh - 75px)',
                bgcolor: alpha(theme.palette.background.paper, 0.5),
                pb: 4
            }}
        >
            <Container maxWidth="lg" sx={{ pt: 4 }}>
                {/* Header */}
                <Paper
                    elevation={0}
                    sx={{
                        mb: 4,
                        p: 3,
                        borderRadius: 3,
                        border: '1px solid',
                        borderColor: 'divider',
                        bgcolor: 'background.paper'
                    }}
                >
                    {/* ... существующий код ... */}

                    <Typography variant="h4" sx={{ fontWeight: 700, color: 'text.primary', mb: 3 }}>
                        {lesson?.title}
                    </Typography>

                    {/* Добавляем индикатор прогресса */}
                    {assignments.length > 0 && (
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                <Typography variant="body2" color="text.secondary">
                                    Прогресс урока
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {assignments.filter(a => a.is_completed).length} из {assignments.length} заданий выполнено
                                </Typography>
                            </Box>
                            <Box sx={{
                                width: '100%',
                                height: 6,
                                bgcolor: alpha(theme.palette.primary.main, 0.1),
                                borderRadius: 3,
                                overflow: 'hidden'
                            }}>
                                <Box
                                    sx={{
                                        width: `${(assignments.filter(a => a.is_completed).length / assignments.length) * 100}%`,
                                        height: '100%',
                                        bgcolor: 'primary.main',
                                        transition: 'width 0.3s ease-in-out'
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                {assignments.map((assignment, index) => (
                                    <Tooltip
                                        key={assignment.id}
                                        title={`${assignment.title} ${assignment.is_completed ? '(выполнено)' : ''}`}
                                    >
                                        <Box
                                            sx={{
                                                width: 24,
                                                height: 24,
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '2px solid',
                                                borderColor: assignment.is_completed ? 'primary.main' : 'divider',
                                                color: assignment.is_completed ? 'primary.main' : 'text.secondary',
                                                bgcolor: assignment.is_completed ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
                                                cursor: 'pointer',
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    transform: 'scale(1.1)'
                                                }
                                            }}
                                            onClick={() => setSelectedAssignment(assignment)}
                                        >
                                            {index + 1}
                                        </Box>
                                    </Tooltip>
                                ))}
                            </Box>
                        </Box>
                    )}
                </Paper>

                {/* Content */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    {lesson?.content?.map((content, index) => (
                        <Paper
                            elevation={0}
                            sx={{
                                borderRadius: 3,
                                overflow: 'visible',
                                border: '1px solid',
                                borderColor: 'divider',
                                bgcolor: 'background.paper',
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: theme.shadows[1]
                                }
                            }}
                        >
                            {content.type === 'text' && (
                                <EditableTextContent
                                    content={content.data}
                                    lessonId={lesson.id}
                                    mentors={courseDetails?.mentors || []}
                                    onContentUpdate={handleContentUpdate}
                                />
                            )}
                            {content.type === 'video' && <VideoContent url={content.data} />}
                            {content.type === 'image' && <ImageContent url={content.data} alt={content.alt} />}
                        </Paper>
                    ))}

                    {/* Assignments */}
                    {assignments.length > 0 && (
                        <Paper
                            elevation={0}
                            sx={{
                                p: 3,
                                borderRadius: 3,
                                border: '1px solid',
                                borderColor: 'divider'
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
                                <BookOpen className="w-5 h-5 text-gray-600" />
                                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                    Задания
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                {assignments.map((assignment) => (
                                    <Paper
                                        key={assignment.id}
                                        elevation={0}
                                        onClick={() => setSelectedAssignment(assignment)}
                                        sx={{
                                            p: 3,
                                            borderRadius: 2,
                                            border: '1px solid',
                                            borderColor: assignment.is_completed ? 'success.light' : 'divider',
                                            bgcolor: assignment.is_completed ? alpha(theme.palette.success.main, 0.04) : 'background.paper',
                                            transition: 'all 0.2s ease-in-out',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                borderColor: assignment.is_completed ? 'success.main' : theme.palette.primary.main,
                                                transform: 'translateY(-1px)',
                                                boxShadow: theme.shadows[1]
                                            }
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ fontWeight: 500, mb: 1 }}
                                                >
                                                    {assignment.title}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {assignment.description}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 2 }}>
                                                {/* Иконка типа задания */}
                                                {assignment.assignment_type === 'sandbox' && (
                                                    <Rocket className="w-5 h-5 text-orange-500" />
                                                )}
                                                {assignment.assignment_type === 'text' && (
                                                    <FileText className="w-5 h-5 text-blue-500" />
                                                )}
                                                {(assignment.assignment_type === 'test' || assignment.assignment_type === 'quiz') && (
                                                    <ClipboardCheck className="w-5 h-5 text-purple-500" />
                                                )}
                                                {/* Статус выполнения */}
                                                {assignment.is_completed ? (
                                                    <CheckCircle className="w-5 h-5 text-green-500" />
                                                ) : (
                                                    <Button
                                                        variant="text"
                                                        size="small"
                                                        endIcon={<ArrowRight className="w-4 h-4" />}
                                                    >
                                                        Решить
                                                    </Button>
                                                )}
                                            </Box>
                                        </Box>
                                    </Paper>
                                ))}
                            </Box>
                        </Paper>
                    )}

                    {/* Navigation */}
                    <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            borderRadius: 3,
                            border: '1px solid',
                            borderColor: 'divider'
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: 2
                        }}>
                            <Button
                                startIcon={<ChevronLeft />}
                                onClick={() => handleNavigation('prev')}
                                disabled={!courseDetails}
                                variant="outlined"
                                sx={{
                                    borderColor: 'divider',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                        bgcolor: 'background.paper'
                                    }
                                }}
                            >
                                Предыдущий урок
                            </Button>

                            <Button
                                variant="contained"
                                onClick={handleComplete}
                                disabled={completing || !allAssignmentsCompleted}
                                sx={{
                                    px: 4,
                                    bgcolor: 'primary.main',
                                    '&:hover': {
                                        bgcolor: 'primary.dark'
                                    }
                                }}
                            >
                                {completing ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <span>Завершение...</span>
                                        <CircularProgress size={16} sx={{ color: 'common.white' }} />
                                    </Box>
                                ) : (
                                    'Завершить урок'
                                )}
                            </Button>

                            <Button
                                endIcon={<ChevronRight />}
                                onClick={() => handleNavigation('next')}
                                disabled={!courseDetails}
                                variant="outlined"
                                sx={{
                                    borderColor: 'divider',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                        bgcolor: 'background.paper'
                                    }
                                }}
                            >
                                Следующий урок
                            </Button>
                        </Box>
                    </Paper>
                </Box>
                {/* Диалог отправки решения */}
                <AssignmentSubmissionDialog
                    open={!!selectedAssignment}
                    onClose={() => setSelectedAssignment(null)}
                    assignment={selectedAssignment}
                    onSubmit={async () => {
                        try {
                            const assignmentsData = await fetchLessonAssignments(token, lessonId);
                            setAssignments(assignmentsData);
                        } catch (error) {
                            console.error('Error updating assignments:', error);
                        }
                    }}
                />
            </Container>
        </Box>
    );

    return (
        <>
            {/* Overlay for mobile */}
            {showSidebar && isMobile && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 9
                    }}
                    onClick={() => setShowSidebar(false)}
                />
            )}
            {sidebarContent}
            {mainContent}
        </>
    );
};

export default LessonPage;