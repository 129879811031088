import React, { useState, useEffect } from 'react';
import { executeSQLQuery } from '../../../services/apiService';
import ReactFlow, {
    Background,
    Controls,
    Handle,
    Position
} from 'reactflow';
import { format } from 'sql-formatter';
import AceEditor from 'react-ace';
import {
    Paper,
    Typography,
    Button,
    Chip,
    IconButton,
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tab,
    Tooltip
} from '@mui/material';
import {
    PlayArrow,
    Schema,
    Close,
    Error as ErrorIcon,
    FormatIndentIncrease as FormatIndent,
    History as HistoryIcon
} from '@mui/icons-material';

// Импорты для ACE editor
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';
import ace from 'ace-builds';


const generateFlowDiagram = (dbConfig) => {
    if (!dbConfig || !dbConfig.tables) return { nodes: [], edges: [] };

    const nodes = dbConfig.tables.map((table, index) => ({
        id: table.name,
        type: 'tableNode',
        position: {
            x: (index % 3) * 320,
            y: Math.floor(index / 3) * 250
        },
        data: {
            label: table.name,
            columns: table.columns.map(col => ({
                name: col.name,
                type: col.type,
                primary_key: col.primary_key,
                foreign_key: table.foreign_keys?.some(fk => fk.column === col.name)
            }))
        }
    }));

    const edges = dbConfig.relationships?.map((rel, index) => ({
        id: `e${index}`,
        source: rel.from_table,
        target: rel.to_table,
        type: 'smoothstep',
        animated: true,
        style: { stroke: '#2196f3' },
        label: rel.relationship_type === 'one_to_many' ? '1:N' : '1:1',
        markerEnd: {
            type: 'arrowclosed',
            width: 20,
            height: 20,
            color: '#2196f3'
        }
    })) || [];

    return { nodes, edges };
};

// Компонент узла таблицы
const TableNode = ({ data }) => (
    <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-gray-200">
        <div className="font-bold text-sm bg-gray-100 p-2 -mx-4 -mt-2 rounded-t-md border-b">
            {data.label}
        </div>
        <div className="mt-2">
            {data.columns.map((col, i) => (
                <div key={i} className="text-sm py-1 flex items-center gap-2">
                    <span className={col.primary_key ? 'text-blue-600 font-bold' : 'text-gray-600'}>
                        {col.name}
                    </span>
                    <span className="text-gray-400 text-xs">{col.type}</span>
                    {col.primary_key && (
                        <span className="text-xs bg-blue-100 text-blue-600 px-1 rounded">PK</span>
                    )}
                    {col.foreign_key && (
                        <span className="text-xs bg-green-100 text-green-600 px-1 rounded">FK</span>
                    )}
                </div>
            ))}
        </div>
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
    </div>
);

// Конфигурация подсказок для SQL
const configureSQLCompleter = (dbConfig) => {
    const tableCompletions = dbConfig.tables.map(table => ({
        caption: table.name,
        value: table.name,
        meta: 'table'
    }));

    const columnCompletions = dbConfig.tables.flatMap(table =>
        table.columns.map(column => ({
            caption: `${table.name}.${column.name}`,
            value: column.name,
            meta: 'column',
            score: 1000
        }))
    );

    const keywordCompletions = [
        'SELECT', 'FROM', 'WHERE', 'INSERT INTO', 'VALUES', 'UPDATE', 'DELETE FROM',
        'JOIN', 'LEFT JOIN', 'RIGHT JOIN', 'INNER JOIN', 'GROUP BY', 'ORDER BY',
        'LIMIT', 'OFFSET', 'AND', 'OR', 'NOT', 'IN', 'BETWEEN', 'LIKE', 'IS NULL',
        'IS NOT NULL', 'ASC', 'DESC'
    ].map(keyword => ({
        caption: keyword,
        value: keyword,
        meta: 'keyword',
        score: 500
    }));

    return {
        getCompletions: (editor, session, pos, prefix, callback) => {
            callback(null, [...tableCompletions, ...columnCompletions, ...keywordCompletions]);
        }
    };
};

const SQLTab = ({ activeComponent, token }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState(null);
    const [error, setError] = useState(null);
    const [history, setHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [schemaDialogOpen, setSchemaDialogOpen] = useState(false);
    const [selectedTable, setSelectedTable] = useState(null);
    const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
    const [rightPanelTab, setRightPanelTab] = useState(0);

    const dbConfig = activeComponent?.configuration?.database;

    const { nodes, edges } = generateFlowDiagram(dbConfig);

    // Настройка редактора при загрузке
    useEffect(() => {
        if (dbConfig) {
            // Настраиваем автодополнение
            const completer = configureSQLCompleter(dbConfig);
            ace.require('ace/ext/language_tools').addCompleter(completer);

            // Загружаем начальный пример
            const examples = generateExampleQueries(dbConfig);
            if (examples.length > 0) {
                setQuery(examples[0].query);
            }
        }
    }, [dbConfig]);

    // Форматирование SQL запроса
    const formatQuery = () => {
        try {
            const formattedQuery = format(query, {
                language: 'sql',
                uppercase: true,
                indentStyle: '    '
            });
            setQuery(formattedQuery);
        } catch (err) {
            setError('Error formatting query: ' + err.message);
        }
    };

    // Обработка результатов запроса с учетом разных типов ответов
    const handleQueryResult = (data) => {
        if (data.error) {
            setError(data.error);
            setHistory(prev => [{
                query,
                timestamp: new Date(),
                error: data.error
            }, ...prev].slice(0, 10));
            return;
        }

        // Проверяем тип ответа
        if (data.result.rows) {
            // SELECT запрос
            const formattedResults = {
                fields: data.result.columns.map(column => ({ name: column })),
                rows: data.result.rows.map(row => {
                    return data.result.columns.reduce((obj, column, index) => {
                        obj[column] = row[index];
                        return obj;
                    }, {});
                })
            };
            setResults(formattedResults);
        } else {
            // INSERT/UPDATE/DELETE запрос
            setResults({
                fields: [{ name: 'Message' }, { name: 'Affected Rows' }],
                rows: [{
                    Message: data.result.message,
                    'Affected Rows': data.result.affected_rows
                }]
            });
        }
        // Обновляем историю
        setHistory(prev => [{
            query,
            timestamp: new Date(),
            rowCount: data.result.rows?.length || data.result.affected_rows || 0,
            type: data.result.rows ? 'SELECT' : 'WRITE',
            message: data.result.message
        }, ...prev].slice(0, 10));
    };


    const executeQuery = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const data = await executeSQLQuery(token, activeComponent.id, query);
            handleQueryResult(data);
        } catch (err) {
            setError(err.response?.data?.error || err.message);
            setHistory(prev => [{
                query,
                timestamp: new Date(),
                error: err.response?.data?.error || err.message
            }, ...prev].slice(0, 10));
        } finally {
            setIsLoading(false);
        }
    };

    if (!activeComponent || activeComponent.type_name !== 'Database') {
        return (
            <div className="p-4 flex items-center justify-center h-full">
                <Paper elevation={0} className="p-8 text-center">
                    <Typography variant="h6" color="textSecondary" className="mb-2">
                        Выберите компонент с типом "Database"
                    </Typography>
                </Paper>
            </div>
        );
    }

    return (
        <div className="flex gap-4 p-4">
            <div className="flex-grow">
                <Paper elevation={0} className="p-4 mb-4">
                    <div className="flex justify-between items-center mb-4">
                        <div className="flex items-center gap-3">
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={executeQuery}
                                disabled={isLoading}
                                startIcon={<PlayArrow />}
                            >
                                Выполнить
                            </Button>
                            <div className="flex items-center gap-2 border-l pl-3">
                                <Tooltip title="Форматировать SQL">
                                    <IconButton
                                        size="small"
                                        onClick={formatQuery}
                                        className="text-gray-600 hover:text-primary"
                                    >
                                        <FormatIndent />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Схема базы данных">
                                    <IconButton
                                        size="small"
                                        onClick={() => setSchemaDialogOpen(true)}
                                        className="text-gray-600 hover:text-primary"
                                    >
                                        <Schema />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="История запросов">
                                    <IconButton
                                        size="small"
                                        onClick={() => setHistoryDialogOpen(true)}
                                        className="text-gray-600 hover:text-primary"
                                    >
                                        <HistoryIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Chip
                                label={`Тип бд: ${dbConfig?.type}`}
                                variant="text"
                            />
                        </div>
                    </div>

                    <AceEditor
                        mode="sql"
                        theme="tomorrow"
                        value={query}
                        onChange={setQuery}
                        name="sql-editor"
                        editorProps={{ $blockScrolling: Infinity }}
                        width="100%"
                        height="200px"
                        fontSize={14}
                        showPrintMargin={false}
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 2,
                            useWorker: false
                        }}
                        onLoad={(editor) => {
                            editor.renderer.setScrollMargin(10, 10, 0, 0);
                            editor.moveCursorTo(0, 0);
                        }}
                    />

                    {error && (
                        <Paper className="mt-4 p-3 bg-red-50">
                            <div className="flex items-center gap-2">
                                <ErrorIcon color="error" />
                                <Typography color="error">{error}</Typography>
                            </div>
                        </Paper>
                    )}

                    {results && (
                        <Paper elevation={0} className="mt-4" sx={{ maxWidth: 600 }}>
                            <div className="p-4 border-b">
                                <Typography variant="subtitle2">
                                    Результаты ({results.rows.length} {results.rows.length === 1 ? 'строка' : 'строк'})
                                </Typography>
                            </div>
                            <div style={{ width: '100%', overflowX: 'hidden' }}> {/* Внешний контейнер с фиксированной шириной */}
                                <TableContainer
                                    className="max-h-[400px]"
                                    sx={{
                                        maxWidth: '100%',
                                        width: '100%',
                                        overflowX: 'auto',
                                        overflowY: 'auto',
                                        '&::-webkit-scrollbar': {
                                            height: '8px',
                                            width: '8px'
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#f1f1f1'
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#888',
                                            borderRadius: '4px',
                                            '&:hover': {
                                                backgroundColor: '#555'
                                            }
                                        }
                                    }}
                                >
                                    <Table
                                        size="small"
                                        stickyHeader
                                        style={{ width: '100%' }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {results.fields?.map((field, index) => (
                                                    <TableCell
                                                        key={index}
                                                        sx={{
                                                            position: 'sticky',
                                                            top: 0,
                                                            backgroundColor: 'background.paper',
                                                            zIndex: 1,
                                                            minWidth: '150px',
                                                            maxWidth: '150px',
                                                            fontWeight: 600,
                                                            padding: '8px'
                                                        }}
                                                    >
                                                        <div className="truncate" title={field.name}>
                                                            {field.name}
                                                        </div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {results.rows?.map((row, rowIndex) => (
                                                <TableRow key={rowIndex} hover>
                                                    {Object.values(row).map((value, cellIndex) => (
                                                        <TableCell
                                                            key={cellIndex}
                                                            sx={{
                                                                minWidth: '150px',
                                                                maxWidth: '150px',
                                                                padding: '8px'
                                                            }}
                                                        >
                                                            <div
                                                                className="truncate"
                                                                title={value === null ? 'null' : String(value)}
                                                            >
                                                                {value === null ? (
                                                                    <span className="text-gray-400">null</span>
                                                                ) : String(value)}
                                                            </div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Paper>
                    )}
                </Paper>
            </div>

            {/* Правая панель */}
            <Paper elevation={0} className="p-4 w-80">
                <Tabs
                    value={rightPanelTab}
                    onChange={(_, newValue) => setRightPanelTab(newValue)}
                    className="mb-4"
                >
                    <Tab label="Примеры" />
                </Tabs>

                {rightPanelTab === 0 && (
                    <div className="space-y-2">
                        {generateExampleQueries(dbConfig).map((example, index) => (
                            <Paper
                                key={index}
                                className="p-3 hover:bg-gray-50 cursor-pointer"
                                onClick={() => setQuery(example.query)}
                            >
                                <Typography variant="subtitle2" className="text-primary">
                                    {example.name}
                                </Typography>
                                <Typography variant="caption" className="text-gray-600">
                                    {example.description}
                                </Typography>
                            </Paper>
                        ))}
                    </div>
                )}
            </Paper>

            {/* Модальное окно схемы БД */}
            <Dialog
                open={schemaDialogOpen}
                onClose={() => setSchemaDialogOpen(false)}
                maxWidth="lg"
                fullWidth
            >
                <div className="p-4">
                    <div className="flex justify-between items-center mb-4">
                        <Typography variant="h6">Database Schema</Typography>
                        <IconButton onClick={() => setSchemaDialogOpen(false)}>
                            <Close/>
                        </IconButton>
                    </div>
                    <div style={{height: '600px', background: '#f8f8f8'}}>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            nodeTypes={{tableNode: TableNode}}
                            fitView
                            minZoom={0.1}
                            maxZoom={1.5}
                            defaultZoom={0.8}
                            nodesDraggable={false}
                            nodesConnectable={false}
                            elementsSelectable={false}
                        >
                            <Controls/>
                            <Background gap={16} size={1}/>
                        </ReactFlow>
                    </div>
                </div>
            </Dialog>

            {/* Модальное окно истории */}
            <Dialog
                open={historyDialogOpen}
                onClose={() => setHistoryDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <div className="p-4">
                    <div className="flex justify-between items-center mb-4">
                        <Typography variant="h6">Query History</Typography>
                        <IconButton onClick={() => setHistoryDialogOpen(false)}>
                            <Close />
                        </IconButton>
                    </div>
                    <div className="space-y-2">
                        {history.map((item, index) => (
                            <Paper
                                key={index}
                                className="p-3 hover:bg-gray-50 cursor-pointer"
                                onClick={() => {
                                    setQuery(item.query);
                                    setHistoryDialogOpen(false);
                                }}
                            >
                                <div className="flex justify-between items-center mb-1">
                                    <Typography variant="caption" className="text-gray-500">
                                        {new Date(item.timestamp).toLocaleString()}
                                    </Typography>
                                    <div className="flex gap-2">
                                        {item.type && (
                                            <Chip
                                                size="small"
                                                label={item.type}
                                                color={item.type === 'SELECT' ? 'primary' : 'success'}
                                            />
                                        )}
                                        {item.error ? (
                                            <Chip
                                                size="small"
                                                label="Error"
                                                color="error"
                                            />
                                        ) : (
                                            <Chip
                                                size="small"
                                                label={`${item.rowCount} ${item.type === 'SELECT' ? 'rows' : 'affected'}`}
                                            />
                                        )}
                                    </div>
                                </div>
                                <Typography variant="body2" className="font-mono">
                                    {item.query}
                                </Typography>
                            </Paper>
                        ))}
                    </div>
                </div>
            </Dialog>

            {/* Модальное окно информации о таблице */}
            <Dialog
                open={Boolean(selectedTable)}
                onClose={() => setSelectedTable(null)}
                maxWidth="md"
                fullWidth
            >
                <div className="p-4">
                    <div className="flex justify-between items-center mb-4">
                        <Typography variant="h6">
                            Table: {selectedTable?.name}
                        </Typography>
                        <IconButton onClick={() => setSelectedTable(null)}>
                            <Close />
                        </IconButton>
                    </div>
                    {selectedTable && <TableDocs table={selectedTable} />}
                </div>
            </Dialog>
        </div>
    );
};

// Компонент документации таблицы
const TableDocs = ({ table }) => (
    <div className="space-y-4">
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Column</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Constraints</TableCell>
                        <TableCell>Default</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {table.columns.map((column) => (
                        <TableRow key={column.name}>
                            <TableCell>
                                <div className="flex items-center gap-2">
                                    <span className="font-mono">{column.name}</span>
                                    {column.primary_key && (
                                        <Chip size="small" label="PK" color="primary" />
                                    )}
                                    {table.foreign_keys.find(fk => fk.column === column.name) && (
                                        <Chip size="small" label="FK" color="secondary" />
                                    )}
                                </div>
                            </TableCell>
                            <TableCell className="font-mono">{column.type}</TableCell>
                            <TableCell>
                                <div className="flex gap-1 flex-wrap">
                                    {column.unique && <Chip size="small" label="UNIQUE" />}
                                    {!column.nullable && <Chip size="small" label="NOT NULL" />}
                                </div>
                            </TableCell>
                            <TableCell className="font-mono">
                                {column.default || '-'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        {table.indexes.length > 0 && (
            <>
                <Typography variant="subtitle2" className="mt-4 mb-2">Indexes</Typography>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Columns</TableCell>
                                <TableCell>Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {table.indexes.map((index, idx) => (
                                <TableRow key={idx}>
                                    <TableCell className="font-mono">{index.name}</TableCell>
                                    <TableCell className="font-mono">{index.columns.join(', ')}</TableCell>
                                    <TableCell>{index.unique ? 'UNIQUE' : 'INDEX'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )}

        {table.foreign_keys.length > 0 && (
            <>
                <Typography variant="subtitle2" className="mt-4 mb-2">Foreign Keys</Typography>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Column</TableCell>
                                <TableCell>References</TableCell>
                                <TableCell>On Delete</TableCell>
                                <TableCell>On Update</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {table.foreign_keys.map((fk, idx) => (
                                <TableRow key={idx}>
                                    <TableCell className="font-mono">{fk.column}</TableCell>
                                    <TableCell className="font-mono">
                                        {`${fk.references.table}(${fk.references.column})`}
                                    </TableCell>
                                    <TableCell>{fk.on_delete}</TableCell>
                                    <TableCell>{fk.on_update}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )}
    </div>
);

// Функция генерации примеров запросов
const generateExampleQueries = (dbConfig) => {
    if (!dbConfig || !dbConfig.tables) {
        return [];
    }

    const examples = [];

    // SELECT для каждой таблицы
    dbConfig.tables.forEach(table => {
        examples.push({
            name: `Select from ${table.name}`,
            query: `SELECT *\nFROM ${table.name}\nLIMIT 5;`,
            description: `Get first 5 rows from ${table.name}`
        });
    });

    // JOIN для связанных таблиц
    if (dbConfig.relationships) {
        dbConfig.relationships.forEach(rel => {
            const fromColumns = rel.columns[0].from_column;
            const toColumns = rel.columns[0].to_column;

            examples.push({
                name: `Join ${rel.from_table} with ${rel.to_table}`,
                query: `SELECT a.*, b.*\nFROM ${rel.from_table} a\nJOIN ${rel.to_table} b ON a.${fromColumns} = b.${toColumns}\nLIMIT 5;`,
                description: `Join example between ${rel.from_table} and ${rel.to_table}`
            });
        });
    }

    // INSERT примеры
    dbConfig.tables.forEach(table => {
        const insertColumns = table.columns
            .filter(col => !col.primary_key && col.default === null)
            .map(col => col.name)
            .join(', ');

        const valuePlaceholders = table.columns
            .filter(col => !col.primary_key && col.default === null)
            .map(col => {
                switch(col.type.toUpperCase()) {
                    case 'VARCHAR':
                    case 'TEXT':
                        return "'example'";
                    case 'INTEGER':
                    case 'SERIAL':
                        return '1';
                    case 'DECIMAL':
                        return '1.0';
                    case 'TIMESTAMP':
                        return 'CURRENT_TIMESTAMP';
                    default:
                        return 'NULL';
                }
            })
            .join(', ');

        if (insertColumns) {
            examples.push({
                name: `Insert into ${table.name}`,
                query: `INSERT INTO ${table.name} (${insertColumns})\nVALUES (${valuePlaceholders});`,
                description: `Insert example for ${table.name}`
            });
        }
    });

    return examples;
};

export default SQLTab;