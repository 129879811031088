import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, MenuItem, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { fetchActiveSandboxes, startCamundaProcess } from '../../services/apiService';
import GenerationLoader from '../GenerationLoader';

const SandboxChoice = ({ token, userInfo, onClose }) => {
    const [sandboxes, setSandboxes] = useState([]);
    const [selectedSandbox, setSelectedSandbox] = useState('');
    const [open, setOpen] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [processId, setProcessId] = useState(null);
    const [sandboxId, setSandboxId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const loadUserSandboxes = async () => {
            try {
                const userSandboxes = await fetchActiveSandboxes(token, userInfo.id, 'all');
                setSandboxes(userSandboxes);
            } catch (error) {
                console.error('Error fetching sandboxes:', error);
            }
        };

        loadUserSandboxes();
    }, [token, userInfo.id]);

    const handleSelectSandbox = (id) => {
        onClose();
        navigate(`/sandbox/${id}`);
    };

    const handleCreateSandbox = async () => {
        try {
            const data = await startCamundaProcess(
                token,
                prompt,
                userInfo.id,
                'base',
                null    // нет существующей песочницы
            );
            setProcessId(data.process_id);
            setSandboxId(data.sandbox_id);
            setIsLoading(true);
        } catch (error) {
            console.error('Error creating sandbox:', error);
        }
    };

    const filteredSandboxes = sandboxes.filter(sandbox =>
        sandbox.name.toLowerCase().includes(selectedSandbox.toLowerCase())
    );

    // Если идет процесс генерации, показываем лоадер
    if (isLoading && processId && sandboxId) {
        return <GenerationLoader token={token} ProcessId={processId} SandboxId={sandboxId} />;
    }

    return (
        <Dialog
            open={true}
            onClose={onClose}
            PaperProps={{
                sx: {
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    borderRadius: '16px',
                    padding: 0,
                    maxWidth: '400px',
                    width: '100%',
                    margin: 'auto',
                },
            }}
        >
            <DialogContent sx={{ padding: '24px' }}>
                <Typography variant="h5" gutterBottom>
                    Выберите песочницу
                </Typography>

                <TextField
                    select
                    fullWidth
                    value={selectedSandbox}
                    onChange={(e) => setSelectedSandbox(e.target.value)}
                    variant="outlined"
                    sx={{
                        marginBottom: '24px',
                        '.MuiSelect-select': {
                            paddingTop: '10px',
                            paddingBottom: '10px',
                        },
                    }}
                >
                    {filteredSandboxes.map(sandbox => (
                        <MenuItem
                            key={sandbox.id}
                            value={sandbox.id}
                            onClick={() => handleSelectSandbox(sandbox.id)}
                        >
                            {sandbox.name}
                        </MenuItem>
                    ))}
                </TextField>

                <Button
                    onClick={() => setOpen(true)}
                    variant="contained"
                    fullWidth
                    sx={{
                        backgroundColor: '#FF6F00',
                        color: 'white',
                        padding: '10px 0',
                        borderRadius: '8px',
                        fontWeight: 'bold',
                        '&:hover': { backgroundColor: '#E65100' },
                    }}
                >
                    Создать новую песочницу
                </Button>

                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogContent>
                        <Typography variant="h6" gutterBottom>
                            Введите промпт для генерации
                        </Typography>
                        <TextField
                            multiline
                            rows={5}
                            fullWidth
                            variant="outlined"
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            placeholder="Например: 'система по учёту ёжиков в лесу' или 'система с микросервисной архитектурой и брокером сообщений для взаимодействия с CRM' "
                            sx={{ marginBottom: '16px' }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCreateSandbox}
                            fullWidth
                            sx={{
                                backgroundColor: '#FF6F00',
                                color: 'white',
                                padding: '10px 0',
                                borderRadius: '8px',
                                fontWeight: 'bold',
                                '&:hover': { backgroundColor: '#E65100' },
                            }}
                        >
                            Начать генерацию
                        </Button>
                    </DialogContent>
                </Dialog>
            </DialogContent>
        </Dialog>
    );
};

export default SandboxChoice;
