import React, { useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { createLowlight } from 'lowlight'
import js from 'highlight.js/lib/languages/javascript'
import python from 'highlight.js/lib/languages/python'
import java from 'highlight.js/lib/languages/java'
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    IconButton,
    Dialog,
    Tab,
    Tabs,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid
} from '@mui/material';
import {
    Bold,
    Italic,
    Code,
    Image as ImageIcon,
    Link as LinkIcon,
    ListOrdered,
    List,
    Plus,
    Trash2,
    Edit2
} from 'lucide-react';

const LessonEditor = ({ lesson, onSave }) => {
    const [activeTab, setActiveTab] = useState('content');
    const [assignments, setAssignments] = useState(lesson?.assignments || []);
    const [showAssignmentDialog, setShowAssignmentDialog] = useState(false);

    const lowlight = createLowlight()
    lowlight.register('js', js)
    lowlight.register('python', python)
    lowlight.register('java', java)

    const editor = useEditor({
        extensions: [
            StarterKit,
            Image,
            Link,
            CodeBlockLowlight.configure({
                lowlight,
            }),
        ],
        content: lesson?.content || '',
        editorProps: {
            attributes: {
                class: 'prose max-w-none focus:outline-none',
            },
        },
    });

    const MenuBar = () => (
        <Box className="flex items-center gap-1 p-2 border-b">
            <IconButton
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor?.isActive('bold') ? 'bg-gray-100' : ''}
            >
                <Bold className="w-4 h-4" />
            </IconButton>
            <IconButton
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor?.isActive('italic') ? 'bg-gray-100' : ''}
            >
                <Italic className="w-4 h-4" />
            </IconButton>
            <IconButton
                onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                className={editor?.isActive('codeBlock') ? 'bg-gray-100' : ''}
            >
                <Code className="w-4 h-4" />
            </IconButton>
            <IconButton onClick={() => editor.chain().focus().toggleBulletList().run()}>
                <List className="w-4 h-4" />
            </IconButton>
            <IconButton onClick={() => editor.chain().focus().toggleOrderedList().run()}>
                <ListOrdered className="w-4 h-4" />
            </IconButton>
            <IconButton onClick={() => {
                const url = window.prompt('Enter image URL:');
                if (url) editor.chain().focus().setImage({ src: url }).run();
            }}>
                <ImageIcon className="w-4 h-4" />
            </IconButton>
            <IconButton onClick={() => {
                const url = window.prompt('Enter link URL:');
                if (url) editor.chain().focus().setLink({ href: url }).run();
            }}>
                <LinkIcon className="w-4 h-4" />
            </IconButton>
        </Box>
    );

    const AssignmentCreator = () => {
        const [newAssignment, setNewAssignment] = useState({
            title: '',
            description: '',
            type: 'text',
            points: 0,
            correct_answer: '',
            options: []
        });

        const handleSave = () => {
            setAssignments([...assignments, newAssignment]);
            setShowAssignmentDialog(false);
        };

        return (
            <Dialog
                open={showAssignmentDialog}
                onClose={() => setShowAssignmentDialog(false)}
                maxWidth="md"
                fullWidth
            >
                <Box className="p-6">
                    <Typography variant="h6" className="mb-4">
                        Новое задание
                    </Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Название задания"
                                value={newAssignment.title}
                                onChange={(e) => setNewAssignment({
                                    ...newAssignment,
                                    title: e.target.value
                                })}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label="Описание задания"
                                value={newAssignment.description}
                                onChange={(e) => setNewAssignment({
                                    ...newAssignment,
                                    description: e.target.value
                                })}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Тип задания</InputLabel>
                                <Select
                                    value={newAssignment.type}
                                    onChange={(e) => setNewAssignment({
                                        ...newAssignment,
                                        type: e.target.value
                                    })}
                                >
                                    <MenuItem value="text">Текстовый ответ</MenuItem>
                                    <MenuItem value="test">Тест</MenuItem>
                                    <MenuItem value="code">Код</MenuItem>
                                    <MenuItem value="sandbox">Песочница</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                type="number"
                                label="Баллы"
                                value={newAssignment.points}
                                onChange={(e) => setNewAssignment({
                                    ...newAssignment,
                                    points: parseInt(e.target.value)
                                })}
                            />
                        </Grid>

                        {newAssignment.type === 'test' && (
                            <Grid item xs={12}>
                                {/* Test options editor */}
                                <Box className="border rounded p-4">
                                    <Typography variant="subtitle2" className="mb-2">
                                        Варианты ответов
                                    </Typography>
                                    {newAssignment.options.map((option, index) => (
                                        <Box key={index} className="flex items-center gap-2 mb-2">
                                            <TextField
                                                fullWidth
                                                size="small"
                                                value={option.text}
                                                onChange={(e) => {
                                                    const newOptions = [...newAssignment.options];
                                                    newOptions[index].text = e.target.value;
                                                    setNewAssignment({
                                                        ...newAssignment,
                                                        options: newOptions
                                                    });
                                                }}
                                            />
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    const newOptions = newAssignment.options.filter((_, i) => i !== index);
                                                    setNewAssignment({
                                                        ...newAssignment,
                                                        options: newOptions
                                                    });
                                                }}
                                            >
                                                <Trash2 className="w-4 h-4" />
                                            </IconButton>
                                        </Box>
                                    ))}
                                    <Button
                                        startIcon={<Plus />}
                                        onClick={() => setNewAssignment({
                                            ...newAssignment,
                                            options: [...newAssignment.options, { text: '', is_correct: false }]
                                        })}
                                    >
                                        Добавить вариант
                                    </Button>
                                </Box>
                            </Grid>
                        )}

                        {newAssignment.type === 'sandbox' && (
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Шаблон окружения</InputLabel>
                                    <Select>
                                        {/* Add environment templates */}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>

                    <Box className="flex justify-end gap-2 mt-6">
                        <Button onClick={() => setShowAssignmentDialog(false)}>
                            Отмена
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleSave}
                        >
                            Создать задание
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        );
    };

    return (
        <Box className="container max-w-4xl mx-auto p-4">
            <Paper className="mb-6">
                <TextField
                    fullWidth
                    label="Название урока"
                    value={lesson?.title || ''}
                    onChange={(e) => onSave({ ...lesson, title: e.target.value })}
                    variant="standard"
                    sx={{ p: 2 }}
                />
            </Paper>

            <Tabs
                value={activeTab}
                onChange={(_, newValue) => setActiveTab(newValue)}
                className="mb-4"
            >
                <Tab label="Содержание" value="content" />
                <Tab label="Задания" value="assignments" />
            </Tabs>

            {activeTab === 'content' && (
                <Paper className="mb-6">
                    <MenuBar />
                    <Box className="p-4">
                        <EditorContent editor={editor} />
                    </Box>
                </Paper>
            )}

            {activeTab === 'assignments' && (
                <Paper className="p-4">
                    <Box className="flex justify-between items-center mb-4">
                        <Typography variant="h6">Задания</Typography>
                        <Button
                            startIcon={<Plus />}
                            variant="contained"
                            onClick={() => setShowAssignmentDialog(true)}
                        >
                            Добавить задание
                        </Button>
                    </Box>

                    <Box className="space-y-4">
                        {assignments.map((assignment, index) => (
                            <Paper key={index} className="p-4 border">
                                <Box className="flex justify-between items-start">
                                    <Box>
                                        <Typography variant="subtitle1" className="font-medium">
                                            {assignment.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {assignment.description}
                                        </Typography>
                                    </Box>
                                    <Box className="flex items-center gap-2">
                                        <IconButton size="small">
                                            <Edit2 className="w-4 h-4" />
                                        </IconButton>
                                        <IconButton size="small">
                                            <Trash2 className="w-4 h-4" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Paper>
                        ))}
                    </Box>
                </Paper>
            )}

            <AssignmentCreator />
        </Box>
    );
};

export default LessonEditor;