import React, {useRef, useState, useEffect, useMemo } from 'react';
import ReactFlow, {
    Handle,
    Position,
    MarkerType,
    useNodesState,
    useEdgesState
} from 'reactflow';
import 'reactflow/dist/style.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';

const TableNode = ({ data }) => {
    return (
        <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-gray-200">
            <div className="font-bold text-sm bg-gray-100 p-2 -mx-4 -mt-2 rounded-t-md border-b">
                {data.label}
            </div>
            <div className="mt-2">
                {data.columns.map((col, i) => (
                    <div key={i} className="text-sm py-1 flex items-center gap-2">
                        <span className={`${col.isPrimary ? 'text-blue-600 font-bold' : 'text-gray-600'}`}>
                            {col.name}
                        </span>
                        <span className="text-gray-400 text-xs">
                            {col.type}
                        </span>
                        {col.isPrimary && (
                            <span className="text-xs bg-blue-100 text-blue-600 px-1 rounded">
                                PK
                            </span>
                        )}
                    </div>
                ))}
            </div>
            <Handle type="target" position={Position.Left} />
            <Handle type="source" position={Position.Right} />
        </div>
    );
};


// Конвертация конфигурации в формат узлов и рёбер
const convertDatabaseConfig = (config) => {
    const NODE_SPACING_X = 300; // Расстояние между колонками
    const NODE_SPACING_Y = 200; // Расстояние между строками
    const NODES_PER_ROW = 3; // Максимум узлов в строке

    const nodes = config.database.tables.map((table, index) => ({
        id: table.name,
        type: 'tableNode',
        position: {
            x: (index % NODES_PER_ROW) * NODE_SPACING_X, // Горизонтальное расположение
            y: Math.floor(index / NODES_PER_ROW) * NODE_SPACING_Y, // Переход на новую строку
        },
        data: {
            label: table.name,
            columns: table.columns.map((col) => ({
                name: col.name,
                type: col.type,
                isPrimary: col.primary_key,
                isUnique: col.unique,
                isNullable: col.nullable,
            })),
        },
    }));

    const edges = config.database.relationships.map((rel, index) => ({
        id: `e${index}`,
        source: rel.from_table,
        target: rel.to_table,
        markerEnd: {
            type: MarkerType.ArrowClosed,
            color: '#888',
        },
        style: { stroke: '#888' },
        type: 'smoothstep',
        animated: true,
    }));

    return { nodes, edges };
};



// Основной компонент диаграммы
const DatabaseDiagram = ({ config }) => {
    const diagramRef = useRef(null); // Ссылка на родительский контейнер
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    const { nodes: initialNodes, edges: initialEdges } = useMemo(
        () => convertDatabaseConfig(config),
        [config]
    );

    const [nodes, setNodes] = useNodesState(initialNodes);
    const [edges, onEdgesChange] = useEdgesState(initialEdges);

    const nodeTypes = useMemo(() => ({
        tableNode: TableNode,
    }), []);

    const handleNodesChange = (changes) => {
        if (changes.length > 0) {
            setNodes((prevNodes) =>
                prevNodes.map((node) => {
                    const change = changes.find((c) => c.id === node.id);
                    if (change) {
                        return { ...node };
                    }
                    return node;
                })
            );
        }
    };

    useEffect(() => {
        const updateSize = () => {
            if (diagramRef.current) {
                const { offsetWidth, offsetHeight } = diagramRef.current;
                setContainerSize((prevSize) => {
                    if (
                        prevSize.width !== offsetWidth ||
                        prevSize.height !== offsetHeight
                    ) {
                        return { width: offsetWidth, height: offsetHeight };
                    }
                    return prevSize; // Размеры не изменились, возвращаем прежнее состояние
                });
            }
        };

        updateSize();
        window.addEventListener('resize', updateSize);

        return () => window.removeEventListener('resize', updateSize);
    }, []);


    // Если размеры неизвестны, ничего не рендерим
    if (!containerSize.width || !containerSize.height) {
        return <div ref={diagramRef} style={{ width: '100%', height: '600px' }} />;
    }

    if (!nodes.length || nodes.some(node => !node.position || node.position.x === undefined || node.position.y === undefined)) {
        console.error("Invalid nodes:", nodes);
        return <div>Загрузка данных...</div>;
    }

    return (
        <div ref={diagramRef} style={{ width: '100%', height: '600px' }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={handleNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                fitView
                style={{ width: containerSize.width, height: containerSize.height }}
            />
        </div>
    );
};

// Функция для создания примера JSON на основе полей сущности
const generateEntityJsonExample = (entity) => {
    const example = {};

    if (!entity || !entity.fields) {
        return example;
    }

    entity.fields.forEach(field => {
        switch (field.type.toLowerCase()) {
            case 'string':
                if (field.name.toLowerCase().includes('name')) {
                    example[field.name] = `Example ${field.name.replace('_', ' ')}`;
                } else if (field.name.toLowerCase().includes('date')) {
                    example[field.name] = new Date().toISOString().split('T')[0];
                } else {
                    example[field.name] = `Value for ${field.name}`;
                }
                break;
            case 'integer':
                if (field.name.toLowerCase().includes('quantity')) {
                    example[field.name] = 100;
                } else if (field.primary_key) {
                    example[field.name] = 1;
                } else {
                    example[field.name] = 42;
                }
                break;
            case 'float':
            case 'decimal':
                example[field.name] = 123.45;
                break;
            case 'boolean':
                example[field.name] = true;
                break;
            case 'date':
                example[field.name] = new Date().toISOString().split('T')[0];
                break;
            case 'datetime':
            case 'timestamp':
                example[field.name] = new Date().toISOString();
                break;
            case 'json':
            case 'array':
                example[field.name] = [];
                break;
            default:
                example[field.name] = null;
        }
    });

    return example;
};

// Функция для генерации примера REST запроса
const generateRequestExample = (endpoint, entityConfig) => {
    // Для GET и DELETE запросов не нужно тело
    if (endpoint.methods.includes('GET') || endpoint.methods.includes('DELETE')) {
        return null;
    }

    if (endpoint.methods.includes('GET') && endpoint.query_parameters?.length) {
        const exampleUrl = `${endpoint.route}?${
            endpoint.query_parameters
                .map(param => `${param.name}=example_value`)
                .join('&')
        }`;

        return {
            url: exampleUrl,
            description: "Пример запроса с параметрами фильтрации"
        };
    }

    if (!endpoint.inputs || !entityConfig) {
        return null;
    }

    const example = {};
    endpoint.inputs.forEach(input => {
        // Изменяем проверку типа: проверяем не только точное совпадение, но и json тип
        if (input.type.toLowerCase() === 'json' || input.type === entityConfig.name) {
            // Генерируем пример на основе конфига сущности
            const entityExample = generateEntityJsonExample(entityConfig);

            // Для PUT/PATCH не исключаем primary key
            if (!(endpoint.methods.includes('PUT') || endpoint.methods.includes('PATCH'))) {
                // Для POST удаляем primary key из примера
                const primaryKeyField = entityConfig.fields.find(f => f.primary_key);
                if (primaryKeyField) {
                    delete entityExample[primaryKeyField.name];
                }
            }

            example[input.name] = entityExample;
        } else if (input.type.toLowerCase() === 'integer') {
            example[input.name] = 1;
        } else if (input.type.toLowerCase() === 'string') {
            example[input.name] = `example-${input.name}`;
        }
    });

    return example;
};


// Функция для генерации примера REST ответа
const generateResponseExample = (endpoint, entityConfig) => {
    if (!endpoint.outputs || !entityConfig) {
        return null;
    }

    // Для всех методов возвращаем прямую структуру сущности, кроме DELETE
    if (endpoint.methods.includes('DELETE')) {
        return {
            "message": "Successfully deleted"
        };
    }

    // Возвращаем прямой пример сущности для всех остальных методов
    return generateEntityJsonExample(entityConfig);
};

const InfoTab = ({activeComponent}) => {
    const [selectedItem, setSelectedItem] = useState(null); // Состояние для отображения выбранного элемента (эндпоинт или сущность)
    const [copiedField, setCopiedField] = useState(null); // Состояние для отслеживания копирования

    const copyToClipboard = (text, field) => {
        navigator.clipboard.writeText(text);
        setCopiedField(field);
        setTimeout(() => setCopiedField(null), 2000);
    };

    const openDetails = (item) => {
        setSelectedItem(item);
    };

    const closeDetails = () => {
        setSelectedItem(null);
    };

    return (
        <div>
            {activeComponent ? (
                <div className="relative">
                    {/* Общая информация о компоненте */}
                    <div
                        className="grid grid-cols-2 gap-y-4 text-sm font-mono text-gray-700 bg-gray-100 p-4 rounded-lg shadow-md">
                        <div className="font-semibold text-gray-600">Название:</div>
                        <div className="col-span-2 text-green-600">{activeComponent.name || 'Не указано'}</div>

                        <div className="font-semibold text-gray-600">Тип:</div>
                        <div className="col-span-2 text-blue-600">{activeComponent.type_name || 'Не указано'}</div>

                        {activeComponent.category_name === "backend" && (
                            <>
                                <div className="font-semibold text-gray-600">Хост:</div>
                                <div className="col-span-2 flex items-center text-yellow-600">
                                    {activeComponent.host || 'Не указано'}
                                    {activeComponent.host && (
                                        <button
                                            onClick={() => copyToClipboard(activeComponent.host, 'Хост')}
                                            className="ml-2 text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded hover:bg-gray-300"
                                        >
                                            <ContentCopyIcon fontSize="small"/>
                                        </button>
                                    )}
                                </div>

                                {activeComponent.type_name === "Database" && (
                                    <>
                                        <div className="font-semibold text-gray-600">Порт:</div>
                                        <div className="col-span-2 flex items-center text-purple-600">
                                            {activeComponent.port || 'Не указано'}
                                            {activeComponent.port && (
                                                <button
                                                    onClick={() => copyToClipboard(activeComponent.port, 'Порт')}
                                                    className="ml-2 text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded hover:bg-gray-300"
                                                >
                                                    <ContentCopyIcon fontSize="small"/>
                                                </button>
                                            )}
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        <div className="font-semibold text-gray-600">Описание:</div>
                        <div className="col-span-2 text-gray-700">{activeComponent.description || 'Не указано'}</div>

                        {/* Эндпоинты для Microservice */}
                        {activeComponent.type_name === 'Microservice' && activeComponent.configuration?.endpoints && (
                            <>
                                <div className="col-span-2 font-semibold text-gray-600 mt-4">Эндпоинты:</div>
                                <div className="col-span-2 space-y-2">
                                    {activeComponent.configuration.endpoints.map((endpoint, index) => (
                                        <div
                                            key={index}
                                            className="text-blue-600 cursor-pointer hover:underline"
                                            onClick={() => openDetails(endpoint)}
                                        >
                                            {endpoint.methods.join(', ')} {endpoint.route}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {/* Сущности */}
                        {activeComponent.type_name === 'Microservice' && activeComponent.configuration?.entities && (
                            <>
                                <div className="col-span-2 font-semibold text-gray-600 mt-4">Сущности:</div>
                                <div className="col-span-2 space-y-2">
                                    {activeComponent.configuration.entities.map((entity, index) => (
                                        <div
                                            key={index}
                                            className="text-blue-600 cursor-pointer hover:underline"
                                            onClick={() => openDetails(entity)}
                                        >
                                            {entity.name}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {activeComponent.type_name === 'Microservice' && (
                            <>
                                <div className="col-span-2 font-semibold text-gray-600 mt-4">GraphQL:</div>
                                <div className="col-span-2 space-y-2">
                                    <div
                                        className="text-blue-600 cursor-pointer hover:underline"
                                        onClick={() => openDetails({isGraphQL: true})}
                                    >
                                        Посмотреть документацию GraphQL API
                                    </div>
                                </div>
                            </>
                        )}

                        {activeComponent.type_name === 'Database' && (
                            <>
                                <div className="col-span-2 font-semibold text-gray-600 mt-4">База данных:</div>
                                <div className="col-span-2 space-y-2">
                                    <div
                                        className="text-blue-600 cursor-pointer hover:underline"
                                        onClick={() => openDetails({isDatabaseDiagram: true})}
                                    >
                                        Посмотреть диаграмму базы данных
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    {/* Всплывающее уведомление о копировании */}
                    {copiedField && (
                        <div
                            className="absolute bottom-3 left-1/2 transform -translate-x-1/2 bg-gray-400 text-black px-4 py-2 rounded shadow-md transition-opacity duration-300">
                            {copiedField} скопирован в буфер обмена
                        </div>
                    )}

                    {/* Модальное окно */}
                    {selectedItem && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                             onClick={closeDetails}>
                            <div
                                className="bg-gray-100 p-6 rounded-lg shadow-lg w-3/4 max-w-lg relative text-sm font-mono text-gray-700"
                                onClick={(e) => e.stopPropagation()}>
                                {/* Кнопка для закрытия модального окна */}
                                <button className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                                        onClick={closeDetails}>
                                    <CloseIcon/>
                                </button>

                                {selectedItem.isGraphQL ? (
                                        <>
                                            <h3 className="text-xl font-bold mb-4">GraphQL API</h3>
                                            <p className="text-gray-700 mb-4">Доступные операции для работы с сущностями
                                                через GraphQL.</p>

                                            <div className="space-y-6">
                                                <div className="mb-4">
                                                    <strong>Queries:</strong>
                                                    <pre className="bg-gray-900 text-gray-100 p-2 rounded mt-2">
{`query GetEntity($entityName: String!, $id: Int!) {
  getEntity(entityName: $entityName, id: $id) {
    # поля сущности
  }
}

query GetAllEntities($entityName: String!) {
  allEntities(entityName: $entityName) {
    # поля сущности
  }
}`}
                </pre>
                                                </div>

                                                <div className="mb-4">
                                                    <strong>Mutations:</strong>
                                                    <pre className="bg-gray-900 text-gray-100 p-2 rounded mt-2">
{`mutation CreateEntity($entityName: String!, $data: JSONObject!) {
  createEntity(entityName: $entityName, data: $data) {
    # поля сущности
  }
}

mutation UpdateEntity($entityName: String!, $id: Int!, $data: JSONObject!) {
  updateEntity(entityName: $entityName, id: $id, data: $data) {
    # поля сущности
  }
}

mutation DeleteEntity($entityName: String!, $id: Int!) {
  deleteEntity(entityName: $entityName, id: $id)
}`}
                </pre>
                                                </div>

                                                <div className="mb-4">
                                                    <strong>Пример использования:</strong>
                                                    <pre className="bg-gray-900 text-gray-100 p-2 rounded mt-2">
{`# Пример запроса сущности
query {
  getEntity(
    entityName: "${activeComponent.configuration?.entities[0]?.name || 'Entity'}", 
    id: 1
  ) {
${activeComponent.configuration?.entities[0]?.fields.map(f => '    ' + f.name).join('\n') || '    # поля сущности'}
  }
}`}
                </pre>
                                                </div>
                                            </div>
                                        </>
                                    ) :

                                    selectedItem.isDatabaseDiagram ? (
                                                <div>
                                                    <h3 className="text-xl font-bold mb-4">Диаграмма базы данных</h3>
                                                    <div className="h-[600px] bg-gray-50">
                                                        <DatabaseDiagram config={activeComponent.configuration} />
                                                    </div>
                                                </div>
                                            ) :

                                        selectedItem.route ? (
                                            <>
                                                <h3 className="text-xl font-bold mb-4">Эндпоинт: {selectedItem.route}</h3>
                                                <p className="text-gray-700 mb-2">
                                                    <strong>Методы:</strong> {selectedItem.methods.join(', ')}</p>
                                                <p className="text-gray-700 mb-2">
                                                    <strong>Описание:</strong> {selectedItem.description}</p>

                                                {selectedItem.query_parameters?.length > 0 && (
                                                    <div className="mb-4">
                                                        <strong>Пример URL с параметрами:</strong>
                                                        <pre className="bg-gray-900 text-gray-100 p-2 rounded mt-2">
                                                            {`${activeComponent.host}${selectedItem.route}?${
                                                                selectedItem.query_parameters
                                                                    .map(param => `${param.name}=example_value`)
                                                                    .join('&')
                                                            }`}
                                                        </pre>
                                                    </div>
                                                )}

                                                <div className="mb-4">
                                                    <strong>Входные параметры:</strong>
                                                    <ul className="list-disc list-inside">
                                                        {selectedItem.inputs.map((input, index) => (
                                                            <li key={index} className="text-gray-700">
                                                                {input.name} ({input.type}) {input.required ? '(Обязательный)' : '(Необязательный)'}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                <div className="mb-4">
                                                    <strong>Параметры запроса:</strong>
                                                    <ul className="list-disc list-inside">
                                                        {selectedItem.query_parameters?.length ? (
                                                            selectedItem.query_parameters.map((param, index) => (
                                                                <li key={index} className="text-gray-700">
                                                                    {param.name} ({param.type})
                                                                    {param.required ? ' (Обязательный)' : ' (Необязательный)'}
                                                                    {param.description && (
                                                                        <span
                                                                            className="text-gray-500"> - {param.description}</span>
                                                                    )}
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <li className="text-gray-500">Нет параметров запроса</li>
                                                        )}
                                                    </ul>
                                                </div>

                                                <div className="mb-4">
                                                    <strong>Выходные параметры:</strong>
                                                    <ul className="list-disc list-inside">
                                                        {selectedItem.outputs.map((output, index) => (
                                                            <li key={index} className="text-gray-700">
                                                                {output.name} ({output.type})
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                {(selectedItem.methods.includes('POST') ||
                                                    selectedItem.methods.includes('PUT') ||
                                                    selectedItem.methods.includes('PATCH')) && (
                                                    <div className="mb-4">
                                                        <strong>Пример запроса:</strong>
                                                        <pre className="bg-gray-900 text-gray-100 p-2 rounded mt-2">
                {JSON.stringify(generateRequestExample(selectedItem,
                    activeComponent.configuration.entities.find(e => e.name === selectedItem.entity)), null, 2)}
            </pre>
                                                    </div>
                                                )}

                                                {!selectedItem.methods.includes('DELETE') && (
                                                    <div className="mb-4">
                                                        <strong>Пример ответа:</strong>
                                                        <pre className="bg-gray-900 text-gray-100 p-2 rounded mt-2">
                {JSON.stringify(generateResponseExample(selectedItem,
                    activeComponent.configuration.entities.find(e => e.name === selectedItem.entity)), null, 2)}
            </pre>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <h3 className="text-xl font-bold mb-4">Сущность: {selectedItem.name}</h3>
                                                <p className="text-gray-700 mb-2">
                                                    <strong>Описание:</strong> {selectedItem.description}</p>

                                                <div className="mb-4">
                                                    <strong>Поля сущности:</strong>
                                                    <ul className="list-disc list-inside">
                                                    {selectedItem.fields.map((field, index) => (
                                                            <li key={index} className="text-gray-700">
                                                        <span
                                                            className="font-bold text-gray-600 font-mono">{field.name}</span>
                                                                {field.nullable ? (
                                                                    <span className="text-gray-600"></span>
                                                                ) : (
                                                                    <span className="text-red-600">*</span>
                                                                )}
                                                                <span className="text-gray-600"> ({field.type})</span>
                                                                <span
                                                                    className="text-gray-500"> - {field.description}</span>
                                                            </li>
                                                        ))}
                                                        <span/>
                                                        <span
                                                            className="text-gray-500 p-1"> * - обязательное поле</span>
                                                    </ul>
                                                </div>

                                                <div className="mb-4">
                                                    <strong>Пример JSON:</strong>
                                                    <pre className="bg-gray-900 text-gray-100 p-2 rounded">
                                                {JSON.stringify(generateEntityJsonExample(selectedItem), null, 2)}
                                            </pre>
                                                </div>
                                            </>
                                        )}
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="text-gray-500">Нет информации о компоненте</div>
            )}
        </div>
    );
};

export default InfoTab;