import React, { useState, useEffect } from 'react';
import { TextField, Typography, Paper } from '@mui/material';

const RouteParamsHandler = ({ url, onUrlChange }) => {
    const [paramValues, setParamValues] = useState({});

    // Извлекаем параметры пути из URL
    const extractPathParams = (url) => {
        const paramRegex = /{([^}]+)}/g;
        const params = {};
        let match;

        while ((match = paramRegex.exec(url)) !== null) {
            params[match[1]] = '';
        }

        return params;
    };

    // Обновляем URL при изменении значений параметров
    const updateUrl = React.useCallback((values) => {
        let newUrl = url;
        Object.entries(values).forEach(([param, value]) => {
            newUrl = newUrl.replace(`{${param}}`, value);
        });
        onUrlChange(newUrl);
    }, [url, onUrlChange]);

    // Инициализация параметров при изменении URL
    useEffect(() => {
        const params = extractPathParams(url);
        setParamValues(params);
        updateUrl(params);
    }, [url, updateUrl]); // добавляем updateUrl в зависимости

    // Обработка изменения значения параметра
    const handleParamChange = (param, value) => {
        const newValues = { ...paramValues, [param]: value };
        setParamValues(newValues);
        updateUrl(newValues);
    };

    // Проверяем, есть ли параметры для отображения
    const hasParams = Object.keys(paramValues).length > 0;

    if (!hasParams) return null;

    return (
        <Paper elevation={0} className="bg-white p-3 mt-2">
            <Typography variant="subtitle2" className="text-gray-600 mb-2">
                Параметры пути
            </Typography>
            <div className="space-y-2">
                {Object.entries(paramValues).map(([param, value]) => (
                    <div key={param} className="flex items-center gap-2">
                        <Typography variant="body2" className="text-gray-500 min-w-[100px]">
                            {param}:
                        </Typography>
                        <TextField
                            size="small"
                            value={value}
                            onChange={(e) => handleParamChange(param, e.target.value)}
                            placeholder={`Введите значение для ${param}`}
                            className="flex-1"
                            sx={{
                                '& .MuiInputBase-input': {
                                    padding: '4px 8px',
                                    fontSize: '0.85rem'
                                }
                            }}
                        />
                    </div>
                ))}
            </div>
        </Paper>
    );
};

export default RouteParamsHandler;