import React, { useState, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import hljs from 'highlight.js';
import 'highlight.js/styles/vs2015.css';
import {
    Paper,
    Box,
    Typography,
    Button,
    CircularProgress,
    Alert,
    IconButton,
    Tooltip
} from '@mui/material';
import {
    Eye,
    Save,
    CheckCircle
} from 'lucide-react';

window.hljs = hljs;

// Конфигурация Quill
const modules = {
    syntax: {
        highlight: text => hljs.highlightAuto(text).value
    },
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'bullet' }],
        ['code-block'],
        ['clean']
    ]
};

// Форматы, которые разрешены в редакторе
const formats = [
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'code-block'
];

const TextAssignment = ({ assignment, token, onSubmitted }) => {
    const [content, setContent] = useState('');
    const [preview, setPreview] = useState(false);
    const [loading] = useState(false);
    const [error, setError] = useState(null);
    const [isDraft, setIsDraft] = useState(false);
    const [lastSaved, setLastSaved] = useState(null);

    // Автосохранение каждые 30 секунд
    const saveDraft = useCallback(() => {
        const key = `draft_${assignment.id}`;
        localStorage.setItem(key, content);
        setIsDraft(true);
        setLastSaved(new Date());
    }, [content, assignment.id]);

    // Загрузка черновика при монтировании
    React.useEffect(() => {
        const key = `draft_${assignment.id}`;
        const saved = localStorage.getItem(key);
        if (saved) {
            setContent(saved);
            setIsDraft(true);
        }
    }, [assignment.id]);

    // Автосохранение
    React.useEffect(() => {
        const timer = setInterval(saveDraft, 30000);
        return () => clearInterval(timer);
    }, [saveDraft]);

    const handleSubmit = async () => {
        if (!content.trim()) {
            setError('Ответ не может быть пустым');
            return;
        }
        onSubmitted({ content });
    };

    return (
        <Paper elevation={0} className="border rounded-xl overflow-hidden">
            {/* Заголовок */}
            <Box className="p-4 bg-gray-50 border-b">
                <Typography variant="h6" className="font-medium">
                    {assignment.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" className="mt-1">
                    {assignment.description}
                </Typography>
            </Box>

            {/* Ошибки */}
            {error && (
                <Alert
                    severity="error"
                    className="m-4"
                    onClose={() => setError(null)}
                >
                    {error}
                </Alert>
            )}

            {/* Редактор/Превью */}
            <Box className="p-4">
                {preview ? (
                    <Box className="prose max-w-none min-h-[200px] p-4 border rounded-lg bg-gray-50">
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </Box>
                ) : (
                    <ReactQuill
                        theme="snow"
                        value={content}
                        onChange={setContent}
                        modules={modules}
                        formats={formats}
                        className="min-h-[200px] rounded-lg border-gray-200"
                    />
                )}
            </Box>

            {/* Панель действий */}
            <Box className="p-4 border-t bg-gray-50 flex items-center justify-between">
                <Box className="flex items-center gap-2">
                    <Tooltip title={preview ? "Редактировать" : "Предпросмотр"}>
                        <IconButton
                            onClick={() => setPreview(!preview)}
                            size="small"
                        >
                            <Eye className="w-5 h-5" />
                        </IconButton>
                    </Tooltip>

                    {isDraft && lastSaved && (
                        <Typography variant="caption" color="text.secondary">
                            Черновик сохранен: {lastSaved.toLocaleTimeString()}
                        </Typography>
                    )}
                </Box>

                <Box className="flex items-center gap-2">
                    <Button
                        startIcon={<Save />}
                        onClick={saveDraft}
                        variant="outlined"
                        disabled={loading}
                    >
                        Сохранить черновик
                    </Button>
                    <Button
                        startIcon={loading ? <CircularProgress size={16} /> : <CheckCircle />}
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={loading || !content.trim()}
                    >
                        {loading ? 'Отправка...' : 'Отправить ответ'}
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default TextAssignment;