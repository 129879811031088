import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CodeIcon, Sparkles, Globe2, Brain } from 'lucide-react';
import AuthDialog from './AuthDialog';

const demoCases = [
    {
        title: "От идеи до запуска за секунды",
        description: "За пару минут AI создаст и развернёт систему по вашему описанию",
        videoUrl: "/demos/deploy.mp4"
    },
    {
        title: "Платформа для экспериментов",
        description: "Полноценная среда для изучения технологий, где можно безопасно экспериментировать",
        videoUrl: "/demos/experiments.mp4"
    },
    {
        title: "AI-powered обучение",
        description: "Персональный AI-ментор, который помогает с каждым шагом",
        videoUrl: "/demos/courses.mp4"
    }
];

const features = [
    {
        icon: <CodeIcon className="h-8 w-8 text-orange-500" />,
        title: "Реальная практика",
        description: "Изучайте технологии с помощью персональных учебных пространств с настоящими микросервисами и базами данных"
    },
    {
        icon: <Brain className="h-8 w-8 text-orange-500" />,
        title: "AI-консультант",
        description: "Персональный ментор доступен 24/7 для помощи и советов. Получайте мгновенные ответы на вопросы и рекомендации."
    },
    {
        icon: <Globe2 className="h-8 w-8 text-orange-500" />,
        title: "Современные технологии",
        description: "Изучайте актуальные инструменты. От базовых концепций до продвинутых технологий - всё в одной платформе."
    }
];

const MainContent = () => {
    const [activeDemo, setActiveDemo] = useState(0);
    const [showAuthDialog, setShowAuthDialog] = useState(false);
    const [initialAuthMode, setInitialAuthMode] = useState('choice');
    const demoSectionRef = useRef(null);
    const videoRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveDemo((current) => (current + 1) % demoCases.length);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const handleVideoLoad = () => {
        if (videoRef.current) {
            videoRef.current.play().catch();
        }
    };

    const handleAuthClick = (mode) => {
        setInitialAuthMode(mode);
        setShowAuthDialog(true);
    };

    const scrollToDemo = () => {
        demoSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const renderDemoSection = () => (
        <div ref={demoSectionRef} className="relative min-h-screen py-20">
            <div className="absolute inset-0">
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(255,109,0,0.1),transparent_50%)]" />
            </div>

            <div className="container mx-auto px-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    whileInView={{opacity: 1, y: 0}}
                    viewport={{once: true}}
                    className="text-center mb-16"
                >
                    <h2 className="text-3xl md:text-4xl font-bold mb-4">
                        Как это работает
                    </h2>
                </motion.div>

                <div className="max-w-4xl mx-auto">
                    {/* Информация о текущем кейсе */}
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        className="mb-8 text-center"
                    >
                        <h3 className="text-2xl font-bold text-orange-500 mb-3">
                            {demoCases[activeDemo].title}
                        </h3>
                        <p className="text-gray-300 text-lg">
                            {demoCases[activeDemo].description}
                        </p>
                    </motion.div>

                    {/* Видео контейнер */}
                    <div className="relative rounded-xl overflow-hidden shadow-2xl bg-gray-900">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={activeDemo}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <video
                                    ref={videoRef}
                                    key={demoCases[activeDemo].videoUrl}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    onLoadedData={handleVideoLoad}
                                    className="w-full aspect-video object-cover"
                                    poster="/demos/poster.jpg"
                                >
                                    <source src={demoCases[activeDemo].videoUrl} type="video/mp4" />
                                    Ваш браузер не поддерживает видео
                                </video>
                            </motion.div>
                        </AnimatePresence>
                    </div>

                    {/* Навигация по демо */}
                    <div className="mt-8 flex flex-col items-center gap-6">
                        <div className="flex justify-center gap-4">
                            {demoCases.map((demoCase, index) => (
                                <button
                                    key={index}
                                    onClick={() => setActiveDemo(index)}
                                    className={`h-2 rounded-full transition-all ${
                                        activeDemo === index
                                            ? 'w-12 bg-orange-500'
                                            : 'w-2 bg-gray-500 hover:bg-gray-400'
                                    }`}
                                    aria-label={`Switch to ${demoCase.title}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="relative min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
            {/* Hero Section */}
            <div className="relative h-screen">
                <div className="absolute inset-0">
                    <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(255,109,0,0.1),transparent_50%)]" />
                </div>

                {/* Контент героя */}
                <div className="relative z-10 container mx-auto px-6 h-full flex items-center pt-20">
                    <div className="max-w-4xl">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                        >
                            <h1 className="text-5xl md:text-7xl font-bold mb-6">
                                <span className="bg-orange-400 bg-clip-text">
                                    Будущее обучения в IT
                                </span>
                                <br />
                                <span className="text-white">уже здесь</span>
                            </h1>
                        </motion.div>

                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.2 }}
                            className="text-xl md:text-2xl text-gray-300 mb-8"
                        >
                            Первая в мире платформа с AI-driven инфраструктурой для практического обучения IT технологиям
                        </motion.p>

                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.4 }}
                            className="flex flex-col sm:flex-row gap-4"
                        >
                            <button
                                onClick={() => handleAuthClick('register')}
                                className="group relative px-8 py-4 rounded-lg bg-gradient-to-r from-orange-500 to-orange-600 text-white font-semibold text-lg transition-all hover:scale-105"
                            >
                                <span className="relative z-10 flex items-center justify-center gap-2">
                                    Начать бесплатно
                                    <Sparkles className="w-5 h-5" />
                                </span>
                            </button>

                            <button
                                onClick={scrollToDemo}
                                className="px-8 py-4 rounded-lg text-lg font-semibold border border-orange-500 text-orange-500 hover:bg-orange-500/10 transition-colors"
                            >
                                Как это работает
                            </button>
                        </motion.div>
                    </div>
                </div>
            </div>

            {/* Features Section */}
            <div className="relative py-20">
                <div className="absolute inset-0 bg-gradient-to-b from-black via-gray-900 to-black">
                    <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(255,109,0,0.1),transparent_50%)]"/>
                </div>

                <div className="container mx-auto px-6">
                    <motion.div
                        initial={{opacity: 0, y: 20}}
                        whileInView={{opacity: 1, y: 0}}
                        viewport={{once: true}}
                        className="text-center mb-16"
                    >
                        <h2 className="text-3xl md:text-4xl font-bold mb-4">
                            Почему это работает
                        </h2>
                        <p className="text-gray-400 max-w-2xl mx-auto">
                            Мы объединили лучшие практики обучения с передовыми технологиями
                        </p>
                    </motion.div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {features.map((feature, index) => (
                            <motion.div
                                key={index}
                                initial={{opacity: 0, y: 20}}
                                whileInView={{opacity: 1, y: 0}}
                                transition={{duration: 0.5, delay: index * 0.2}}
                                viewport={{once: true}}
                                className="group relative p-8 rounded-2xl overflow-hidden"
                            >
                                <div className="absolute inset-0 bg-gradient-to-br from-gray-800 to-gray-900 transition-opacity group-hover:opacity-0"/>
                                <div className="absolute inset-0 bg-gradient-to-br from-orange-500/20 to-orange-600/20 opacity-0 group-hover:opacity-100 transition-opacity"/>

                                <div className="relative z-10">
                                    <div className="h-16 w-16 mb-6 rounded-full bg-orange-500/20 flex items-center justify-center transform group-hover:scale-110 transition-transform">
                                        {feature.icon}
                                    </div>
                                    <h3 className="text-xl font-bold mb-4 group-hover:text-orange-400 transition-colors">
                                        {feature.title}
                                    </h3>
                                    <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                                        {feature.description}
                                    </p>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>

            {renderDemoSection()}

            <AuthDialog
                open={showAuthDialog}
                onClose={() => setShowAuthDialog(false)}
                onSuccess={(token) => {
                    setShowAuthDialog(false);
                }}
                initialMode={initialAuthMode}
            />
        </div>
    );
};

export default MainContent;