import React, {useState, useEffect, useRef, useContext} from 'react';
import {
    Box,
    Dialog,
    Typography,
    Alert,
    CircularProgress
} from '@mui/material';
import {
    submitAssignment,
    getAssignmentFeedback
} from '../../services/apiService';
import { AuthContext } from '../../App';
import AssignmentFeedback from './AssignmentFeedback';
import TextAssignment from './TextAssignment';
import TestAssignment from './TestAssignment';
import SandboxAssignment from '../sandbox/SandboxAssignment'

const AssignmentSubmissionDialog = ({
                                        open,
                                        assignment,
                                        onSubmit,
                                        onClose,
                                        ...props
                                    }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { token } = useContext(AuthContext);
    const [feedback, setFeedback] =  useState(assignment?.submission_status?.feedback || null);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const checkFeedbackInterval = useRef(null);

    // Очистка интервала при размонтировании или закрытии
    useEffect(() => {
        return () => {
            if (checkFeedbackInterval.current) {
                clearInterval(checkFeedbackInterval.current);
            }
        };
    }, []);


    useEffect(() => {
        setFeedback(assignment?.submission_status?.feedback || null);
    }, [assignment]);

    const startFeedbackPolling = () => {
        setIsPending(true);
        let attempts = 0;
        const maxAttempts = 24; // 2 минуты при интервале в 5 секунд

        checkFeedbackInterval.current = setInterval(async () => {
            try {
                attempts++;
                if (attempts >= maxAttempts) {
                    clearInterval(checkFeedbackInterval.current);
                    setIsPending(false);
                    setError("Не удалось получить фидбек. Он будет доступен позже в истории решений.");
                    return;
                }

                const response = await getAssignmentFeedback(token, assignment.id);

                if (response.status === 'completed' && response.feedback) {
                    clearInterval(checkFeedbackInterval.current);
                    setFeedback(response.feedback);
                    setIsPending(false);
                }
            } catch (error) {
                console.error('Error checking feedback:', error);
            }
        }, 5000);
    };

    const handleSubmit = async (data) => {
        setIsSubmitting(true);
        setError(null);

        try {
            const response = await submitAssignment(token, assignment.id, {
                ...data,
                assignment: assignment.id
            });

            if (response.status === 'pending' ||
                response.message === "Фидбек будет доступен позже" ||
                (response.feedback === "Фидбек будет доступен позже")) {
                startFeedbackPolling();
            } else if (response.status === 'completed' && response.feedback) {
                setFeedback(response.feedback);
            } else if (response.feedback) {
                setFeedback(response.feedback);
            }

            // Только уведомляем родителя об успешной отправке
            onSubmit && onSubmit(response);
        } catch (err) {
            console.error('Submission error:', err);
            setError(err.message || "Ошибка при отправке решения");
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleRetry = () => {
        setFeedback(null);
        setError(null);
        setIsPending(false);
        if (checkFeedbackInterval.current) {
            clearInterval(checkFeedbackInterval.current);
        }
    };

    if (!assignment) {
        return (
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="md"
                fullWidth
                {...props}
            >
                <Box className="p-4">
                    <Alert severity="info">
                        <CircularProgress size={20} className="mr-2" />
                        Загрузка задания...
                    </Alert>
                </Box>
            </Dialog>
        );
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (!isSubmitting && !isPending) {
                    onClose();
                }
            }}
            maxWidth="md"
            fullWidth
            {...props}
        >
            <Box className="p-4 relative">
                {/* Индикатор загрузки при отправке */}
                {isSubmitting && (
                    <Box className="absolute inset-0 bg-black/50 flex items-center justify-center z-50">
                        <Box className="bg-white p-4 rounded-lg flex items-center">
                            <CircularProgress size={24} />
                            <Typography className="ml-2">
                                Отправка решения...
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* Индикатор ожидания фидбека */}
                {isPending && (
                    <Alert
                        severity="info"
                        icon={<CircularProgress size={20} />}
                        className="mb-4"
                    >
                        Ваше решение обрабатывается. Фидбек будет доступен в течение нескольких минут.
                    </Alert>
                )}

                {/* Отображение ошибок */}
                {error && (
                    <Alert
                        severity="error"
                        onClose={() => setError(null)}
                        className="mb-4"
                    >
                        {error}
                    </Alert>
                )}

                {/* Отображение фидбека */}
                {feedback && (
                    <AssignmentFeedback
                        feedback={feedback}
                        onRetry={handleRetry}
                        onComplete={onClose}
                    />
                )}

                {/* Основной контент диалога */}
                {!feedback && !isPending && (
                    <Box>
                        {assignment.assignment_type === 'text' && (
                            <TextAssignment
                                assignment={assignment}
                                token={token}
                                onSubmitted={handleSubmit}
                            />
                        )}

                        {assignment.assignment_type === 'test' && (
                            <TestAssignment
                                assignment={assignment}
                                token={token}
                                onSubmitted={handleSubmit}
                            />
                        )}

                        {assignment.assignment_type === 'sandbox' && (
                            <SandboxAssignment
                                assignment={assignment}
                                token={token}
                                hideContainer={false}
                            />
                        )}
                    </Box>
                )}
            </Box>
        </Dialog>
    );
};

export default AssignmentSubmissionDialog;