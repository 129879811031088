import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { TextField, Autocomplete, Chip, CircularProgress, Button, Alert, Snackbar } from '@mui/material';
import { searchUsers, updateSandboxCoworkers } from '../../services/apiService';
import debounce from 'lodash/debounce';

const ShareDialog = ({ open, onClose, token, sandboxId, currentCoworkers = [] }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCoworkers, setSelectedCoworkers] = useState(currentCoworkers);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);

    // Создаем базовую функцию поиска
    const searchUsersBase = useCallback(async (search) => {
        if (!search) return;
        setLoading(true);
        try {
            const results = await searchUsers(token, search);
            setUsers(results);
        } catch (error) {
            console.error('Error searching users:', error);
            setError('Не удалось найти пользователей');
        } finally {
            setLoading(false);
        }
    }, [token]);

    // Создаем debounced версию функции
    const debouncedSearch = useMemo(
        () => debounce(searchUsersBase, 300),
        [searchUsersBase]
    );

    // Очищаем debounced функцию при размонтировании
    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    useEffect(() => {
        if (searchTerm) {
            debouncedSearch(searchTerm);
        }
    }, [searchTerm, debouncedSearch]);

    const handleUserSelect = (event, newValue) => {
        setSelectedCoworkers(newValue);
        setHasChanges(true);
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            await updateSandboxCoworkers(token, sandboxId, selectedCoworkers.map(user => user.id));
            setSaveSuccess(true);
            setHasChanges(false);
            setTimeout(() => {
                onClose();
            }, 1500); // Закрываем диалог через 1.5 секунды после успешного сохранения
        } catch (error) {
            console.error('Error updating coworkers:', error);
            setError('Не удалось сохранить изменения');
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        if (hasChanges) {
            if (window.confirm('Есть несохраненные изменения. Закрыть без сохранения?')) {
                onClose();
            }
        } else {
            onClose();
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        borderRadius: '16px',
                        width: '100%',
                        maxWidth: '500px',
                    }
                }}
            >
                <DialogTitle>Доступ к песочнице</DialogTitle>
                <DialogContent>
                    <div className="mb-4">
                        <Autocomplete
                            multiple
                            options={users}
                            value={selectedCoworkers}
                            loading={loading}
                            getOptionLabel={(option) => option.username}
                            onChange={handleUserSelect}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Поиск по имени пользователя или email..."
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={option.id}
                                        label={option.username}
                                        {...getTagProps({ index })}
                                        sx={{
                                            backgroundColor: 'primary.main',
                                            color: 'white',
                                            '& .MuiChip-deleteIcon': {
                                                color: 'white',
                                            },
                                        }}
                                    />
                                ))
                            }
                        />
                    </div>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions sx={{ padding: '16px' }}>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        disabled={loading}
                    >
                        Отмена
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        disabled={loading || !hasChanges}
                        sx={{
                            bgcolor: 'primary.main',
                            '&:hover': {
                                bgcolor: 'primary.dark',
                            },
                        }}
                    >
                        {loading ? 'Сохранение...' : 'Сохранить'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={saveSuccess}
                autoHideDuration={1500}
                onClose={() => setSaveSuccess(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity="success" elevation={6} variant="filled">
                    Изменения успешно сохранены
                </Alert>
            </Snackbar>
        </>
    );
};

export default ShareDialog;