import { useState, useEffect } from 'react';

const WelcomeTooltip = ({ onAccept, onDecline }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 30000);

        return () => clearTimeout(timer);
    }, []);

    if (!isVisible) return null;

    return (
        <div className="fixed bottom-24 right-24 z-50">
            <div className="relative bg-white/95 p-4 rounded-lg shadow-lg max-w-xs">
                <div className="absolute -bottom-2 right-4 w-4 h-4 bg-white/95 transform rotate-45" />

                <p className="text-gray-800 mb-4">
                    Привет! Я твой персональный кот-ассистент. Могу ли я рассказать вам как пользоваться нашей системой или помочь чем-то еще?
                </p>

                <div className="flex flex-col gap-2">
                    <button
                        onClick={() => {
                            setIsVisible(false);
                            onAccept();
                        }}
                        className="w-full px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors text-sm"
                    >
                        Да, расскажи как все устроено
                    </button>

                    <button
                        onClick={() => {
                            setIsVisible(false);
                            onDecline();
                        }}
                        className="w-full px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors text-sm"
                    >
                        Нет, я опытный пользователь
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WelcomeTooltip;