// AssignmentTab.js
import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Alert,
    CircularProgress,
    Paper,
    Divider
} from '@mui/material';
import {
    SendHorizontal,
    Clock,
    CheckCircle2,
    BookOpen,
    AlertCircle
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { submitAssignment } from '../../../services/apiService';

const AssignmentTab = ({ assignment, assignmentStatus, token }) => {
    const [solution, setSolution] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [feedback, setFeedback] = useState(assignmentStatus?.feedback);

    const handleSubmit = async () => {
        if (!solution.trim()) {
            setError('Пожалуйста, опишите ваше решение');
            return;
        }

        setIsSubmitting(true);
        setError(null);

        try {
            const response = await submitAssignment(token, assignment.id, {
                content: solution,
                type: 'sandbox'
            });

            if (response.feedback) {
                setFeedback(response.feedback);
            }
        } catch (err) {
            setError(err.message || 'Ошибка при отправке решения');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box className="space-y-6 max-w-2xl mx-auto">
            {/* Заголовок */}
            <Paper elevation={0} className="p-6 bg-white rounded-lg">
                <Box className="flex items-center gap-2 mb-4">
                    <BookOpen className="w-5 h-5 text-blue-600" />
                    <Typography variant="h6" className="font-semibold">
                        {assignment.title}
                    </Typography>
                </Box>

                {assignment.deadline && (
                    <Alert
                        icon={<Clock className="w-4 h-4" />}
                        severity="info"
                        className="mb-4"
                    >
                        Срок сдачи: {new Date(assignment.deadline).toLocaleDateString()}
                    </Alert>
                )}

                <Box className="prose max-w-none">
                    <ReactMarkdown>
                        {assignment.description}
                    </ReactMarkdown>
                </Box>

                {assignment.criteria && (
                    <Box className="mt-4 bg-gray-50 p-4 rounded-lg">
                        <Typography variant="subtitle2" className="font-semibold mb-2">
                            Критерии оценки:
                        </Typography>
                        <ul className="list-disc pl-4 space-y-1">
                            {assignment.criteria.map((criterion, index) => (
                                <li key={index} className="text-gray-700">
                                    {criterion}
                                </li>
                            ))}
                        </ul>
                    </Box>
                )}
            </Paper>

            {/* Форма отправки решения */}
            {!feedback && (
                <Paper elevation={0} className="p-6 bg-white rounded-lg">
                    <Box className="flex items-center gap-2 mb-4">
                        <SendHorizontal className="w-5 h-5 text-green-600" />
                        <Typography variant="h6" className="font-semibold">
                            Отправка решения
                        </Typography>
                    </Box>

                    {error && (
                        <Alert
                            severity="error"
                            className="mb-4"
                            onClose={() => setError(null)}
                        >
                            {error}
                        </Alert>
                    )}

                    <textarea
                        value={solution}
                        onChange={(e) => setSolution(e.target.value)}
                        placeholder="Опишите ваше решение..."
                        className="w-full min-h-[200px] p-4 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none mb-4"
                    />

                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isSubmitting || !solution.trim()}
                        startIcon={isSubmitting ? (
                            <CircularProgress size={20} />
                        ) : (
                            <CheckCircle2 className="w-5 h-5" />
                        )}
                        className="w-full"
                    >
                        {isSubmitting ? 'Отправка...' : 'Отправить решение'}
                    </Button>
                </Paper>
            )}

            {/* Фидбек */}
            {feedback && (
                <Paper elevation={0} className="p-6 bg-white rounded-lg">
                    <Box className="flex items-center gap-2 mb-4">
                        <AlertCircle className="w-5 h-5 text-blue-600" />
                        <Typography variant="h6" className="font-semibold">
                            Результат проверки
                        </Typography>
                    </Box>

                    <Divider className="mb-4" />

                    <Box className="prose max-w-none bg-gray-50 p-4 rounded-lg">
                        <ReactMarkdown>{feedback}</ReactMarkdown>
                    </Box>

                    <Box className="mt-4 flex justify-end">
                        <Button
                            variant="outlined"
                            onClick={() => setFeedback(null)}
                            startIcon={<SendHorizontal className="w-5 h-5" />}
                        >
                            Отправить новое решение
                        </Button>
                    </Box>
                </Paper>
            )}
        </Box>
    );
};

export default AssignmentTab;