import React, {useState, useEffect, useRef} from 'react';
import { Box, Button, Alert, CircularProgress, alpha } from '@mui/material';
import { Code as CodeIcon } from '@mui/icons-material';
import {
    getAssignmentStatus,
    deployTemplate,
    getProcessStage
} from '../../services/apiService';


const SandboxAssignment = ({
                               assignment,
                               token,
                               hideContainer = false
                           }) => {
    const [setStatus] = useState(null);
    const [isDeploying, setIsDeploying] = useState(false);
    const [error, setError] = useState(null);
    const intervalRef = useRef(null);

    // Очистка интервала при размонтировании
    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const loadStatus = async () => {
            try {
                const data = await getAssignmentStatus(token, assignment.id);
                setStatus(data);
            } catch (error) {
                console.error('Error loading assignment status:', error);
            }
        };

        if (assignment && token) {
            loadStatus();
        }
    }, [setStatus, assignment, token]);

    const handleOpenSandbox = async () => {
        setIsDeploying(true);
        setError(null);

        try {
            // Проверяем существующее окружение в задании
            if (assignment.sandbox_environment) {
                const { status, sandbox } = assignment.sandbox_environment;

                // Если окружение активно - открываем его
                if (status === 'active' && sandbox) {
                    window.open(`/sandbox/${sandbox}?assignment_id=${assignment.id}&mode=assignment`, '_blank');
                    setIsDeploying(false);
                    return;
                }

                // Если окружение в процессе создания - ждем завершения
                if (status === 'creating') {
                    let attempts = 0;
                    const maxAttempts = 60; // 3 минуты максимум

                    intervalRef.current = setInterval(async () => {
                        attempts++;
                        if (attempts > maxAttempts) {
                            clearInterval(intervalRef.current);
                            setError('Превышено время ожидания создания окружения');
                            setIsDeploying(false);
                            return;
                        }

                        const assignmentStatus = await getAssignmentStatus(token, assignment.id);
                        if (assignmentStatus.sandbox_environment?.status === 'active') {
                            clearInterval(intervalRef.current);
                            window.open(
                                `/sandbox/${assignmentStatus.sandbox_environment.sandbox}?assignment_id=${assignment.id}&mode=assignment`,
                                '_blank'
                            );
                            setIsDeploying(false);
                        }
                    }, 3000);
                    return;
                }
            }

            // Создаем новое окружение
            const response = await deployTemplate(
                token,
                assignment.sandbox_details.template_id,
                assignment.id  // Передаем ID задания
            );

            // Ждем завершения создания
            let attempts = 0;
            const maxAttempts = 60;

            intervalRef.current = setInterval(async () => {
                try {
                    attempts++;
                    if (attempts > maxAttempts) {
                        clearInterval(intervalRef.current);
                        throw new Error('Превышено время ожидания создания окружения');
                    }

                    const [{ stage }] = await getProcessStage(token, response.process_id);
                    if (stage >= 2) {
                        clearInterval(intervalRef.current);
                        window.open(
                            `/sandbox/${response.sandbox_id}?assignment_id=${assignment.id}&mode=assignment`,
                            '_blank'
                        );
                        setIsDeploying(false);
                    }
                } catch (error) {
                    clearInterval(intervalRef.current);
                    throw error;
                }
            }, 3000);

        } catch (error) {
            console.error('Error:', error);
            setError(error.message || 'Ошибка при подготовке окружения');
            setIsDeploying(false);
        }
    };

    return (
        <Box sx={{ mt: hideContainer ? 0 : 2 }}>
            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            <Button
                variant="contained"
                onClick={handleOpenSandbox}
                startIcon={isDeploying ? (
                    <CircularProgress size={20} color="inherit" />
                ) : (
                    <CodeIcon />
                )}
                disabled={isDeploying}
                sx={{
                    bgcolor: 'warning.main',
                    '&:hover': {
                        bgcolor: 'warning.dark'
                    },
                    '&.Mui-disabled': {
                        bgcolor: alpha('#FF5722', 0.3)
                    }
                }}
            >
                {isDeploying ? 'Подготовка песочницы...' : 'Открыть песочницу'}
            </Button>

            {assignment.deadline && (
                <Alert
                    severity="info"
                    sx={{
                        mt: 2,
                        '& .MuiAlert-icon': {
                            color: 'warning.main'
                        }
                    }}
                >
                    Срок сдачи: {new Date(assignment.deadline).toLocaleDateString()}
                </Alert>
            )}
        </Box>
    );
};

export default SandboxAssignment;