import React, { useState, useContext } from 'react';
import { AuthContext } from '../App';
import AuthDialog from '../components/AuthDialog';
import { Box, Paper, Typography, Button } from '@mui/material';
import { Lock } from 'lucide-react';

const ProtectedRouteWrapper = ({ token, userInfo, component: Component, title = "Доступ ограничен", message = "Для просмотра этого контента необходимо войти в систему или зарегистрироваться" }) => {
    const { isAuthenticated, isLoading } = useContext(AuthContext);
    const [showAuthDialog, setShowAuthDialog] = useState(false);

    if (isLoading) {
        return (
            <Box className="flex justify-center items-center min-h-screen">
                <div>Loading...</div>
            </Box>
        );
    }

    if (!isAuthenticated) {
        return (
            <Box className="container mx-auto px-4 py-8 max-w-2xl">
                <Paper
                    elevation={0}
                    className="p-8 text-center"
                    sx={{
                        backgroundColor: '#1a1a1a',
                        backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0.05), rgba(255,255,255,0.02))',
                        borderRadius: '16px',
                    }}
                >
                    <Lock className="w-16 h-16 mx-auto mb-4 text-orange-500" />
                    <Typography variant="h4" className="mb-4 font-bold text-gray-100">
                        {title}
                    </Typography>
                    <Typography variant="body1" className="mb-6 text-gray-400">
                        {message}
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => setShowAuthDialog(true)}
                        className="py-3 px-8 text-lg font-medium"
                        sx={{
                            background: 'linear-gradient(45deg, #FF6B6B, #FF8E53)',
                            borderRadius: '12px',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #FF8E53, #FF6B6B)',
                            }
                        }}
                    >
                        Войти или зарегистрироваться
                    </Button>
                </Paper>

                <AuthDialog
                    open={showAuthDialog}
                    onClose={() => setShowAuthDialog(false)}
                    onSuccess={() => {
                        setShowAuthDialog(false);
                    }}
                    initialMode="choice"
                />
            </Box>
        );
    }

    return <Component token={token} userInfo={userInfo} />;
};

export default ProtectedRouteWrapper;