import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
    Box,
    Typography,
    Button,
    Divider,
    Paper
} from '@mui/material';
import {
    CheckCircle2,
    RefreshCcw,
    Rocket
} from 'lucide-react';

const AssignmentFeedback = ({
                                feedback,
                                onRetry,
                                onComplete
                            }) => {
    return (
        <Paper elevation={0} className="p-6 border rounded-xl">
            {/* Заголовок */}
            <Box className="flex items-center gap-2 mb-4">
                <CheckCircle2 className="w-5 h-5 text-green-500" />
                <Typography variant="h6" className="font-semibold">
                    Результат проверки
                </Typography>
            </Box>

            {/* Фидбек с поддержкой markdown */}
            <Box className="prose max-w-none bg-gray-50 p-4 rounded-lg mb-6">
                <ReactMarkdown
                    className="text-sm"
                    components={{
                        code({node, inline, className, children, ...props}) {
                            const match = /language-(\w+)/.exec(className || '')
                            return !inline && match ? (
                                <SyntaxHighlighter
                                    style={vscDarkPlus}
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                >
                                    {String(children).replace(/\n$/, '')}
                                </SyntaxHighlighter>
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            )
                        }
                    }}
                >
                    {feedback}
                </ReactMarkdown>
            </Box>

            <Divider className="my-4" />

            {/* Кнопки действий */}
            <Box className="flex justify-between items-center">
                <Button
                    variant="outlined"
                    startIcon={<RefreshCcw className="w-4 h-4" />}
                    onClick={onRetry}
                    className="text-gray-600"
                >
                    Отправить новый ответ
                </Button>

                <Button
                    variant="contained"
                    startIcon={<Rocket className="w-4 h-4" />}
                    onClick={onComplete}
                    className="bg-green-500 hover:bg-green-600"
                >
                    Завершить задание
                </Button>
            </Box>
        </Paper>
    );
};

export default AssignmentFeedback;