import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    TextField,
    Box,
    Tabs,
    Tab,
    Typography,
    Alert,
    Select,
    MenuItem
} from '@mui/material';
import {
    Pencil,
    Wrench,
    BookTemplate,
    Wand2,
    Settings2,
    Trash2
} from 'lucide-react';
import {fetchEnvironmentTemplates} from "../../services/apiService";

// Компонент панели с содержимым для каждого типа создания
const TabPanel = ({ children, value, index }) => (
    <div hidden={value !== index} className="mt-4">
        {value === index && children}
    </div>
);

const CreateSandboxDialog = ({
                                 open,
                                 onClose,
                                 onSubmit,
                                 token,
                                 error,
                                 setError,
                                 componentTypes
                             }) => {
    // Состояния
    const [activeTab, setActiveTab] = useState(0);
    const [prompt, setPrompt] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
    const [manualMode, setManualMode] = useState('semi'); // 'semi' или 'expert'
    const [selectedComponents, setSelectedComponents] = useState([]);
    const [componentConfig, setComponentConfig] = useState({
        type: null,
        subtype: null,
        prompt: '',
        name: ''
    });

    // Функция валидации системного имени
    const validateSystemName = (name) => {
        const regex = /^[a-zA-Z0-9-]{1,20}$/;
        return regex.test(name);
    };

    const COMPONENT_SUBTYPES = {
        'Database': [
            { id: 'postgresql', name: 'PostgreSQL' },
            { id: 'mongodb', name: 'MongoDB' }
        ]
    };

    const getSubtypes = (type) => {
        const selectedType = componentTypes.find(t => t.id === type);
        if (!selectedType) return [];

        return COMPONENT_SUBTYPES[selectedType.name] || [];
    };

    const ALLOWED_COMPONENTS = {
        'Database': {
            description: 'База данных для хранения информации. Поддерживаются PostgreSQL и MongoDB.'
        },
        'Microservice': {
            description: 'Независимый микросервис с собственной базой данных и API.'
        },
        'Backend service': {
            description: 'Сервис для обработки бизнес-логики, может включать несколько связанных функций.'
        }
    };

    useEffect(() => {
        const loadTemplates = async () => {
            if (activeTab === 2) {  // Вкладка "Из шаблона"
                setIsLoadingTemplates(true);
                try {
                    const data = await fetchEnvironmentTemplates(token);
                    setTemplates(data);
                } catch (err) {
                    setError('Ошибка загрузки шаблонов');
                } finally {
                    setIsLoadingTemplates(false);
                }
            }
        };

        loadTemplates();
    }, [activeTab, token, setError]);

    const handleTypeChange = (typeId) => {
        setComponentConfig({
            ...componentConfig,
            type: typeId,
            subtype: null
        });
    };

    // Обработчик смены вкладки
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setError(null); // Сбрасываем ошибки при смене вкладки
    };

    // Обработчик отправки формы
    const handleSubmit = () => {
        switch (activeTab) {
            case 0: // По описанию
                if (!prompt.trim()) {
                    setError('Введите описание для генерации');
                    return;
                }
                break;
            case 1: // Вручную
                if (selectedComponents.length === 0) {
                    setError('Добавьте хотя бы один компонент');
                    return;
                }
                break;
            case 2: // Из шаблона
                if (!selectedTemplate) {
                    setError('Выберите шаблон');
                    return;
                }
                break;
            case 3: // Мне повезёт
                break;
            default:
                setError('Неизвестный тип создания');
                return;
        }

        onSubmit({
            type: activeTab,
            prompt: activeTab === 0 ? prompt : '',
            components: activeTab === 1 ? selectedComponents : null,
            templateId: activeTab === 2 ? selectedTemplate : null,
            isLucky: activeTab === 3
        });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                className: "rounded-xl",
                style: { padding: '8px' }
            }}
        >
            <DialogTitle className="text-2xl font-bold px-6 pt-4">
                Создание новой песочницы
            </DialogTitle>

            <DialogContent className="px-6">
                {error && (
                    <Alert
                        severity="error"
                        className="mb-6 mt-2 rounded-lg border border-red-100"
                        onClose={() => setError(null)}
                    >
                        {error}
                    </Alert>
                )}

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="mt-2">
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        TabIndicatorProps={{
                            className: "bg-blue-500"
                        }}
                    >
                        <Tab
                            icon={<Pencil className="w-5 h-5 text-blue-500" />}
                            label="По описанию"
                            className="text-gray-700 hover:text-blue-600 transition-colors"
                        />
                        <Tab
                            icon={<Wrench className="w-5 h-5 text-purple-500" />}
                            label="Вручную"
                            className="text-gray-700 hover:text-blue-600 transition-colors"
                        />
                        <Tab
                            icon={<BookTemplate className="w-5 h-5 text-green-500" />}
                            label="Из шаблона"
                            className="text-gray-700 hover:text-blue-600 transition-colors"
                        />
                        <Tab
                            icon={<Wand2 className="w-5 h-5 text-amber-500" />} // Перенесли Wand2 сюда для "мне повезёт"
                            label="Мне повезёт"
                            className="text-gray-700 hover:text-blue-600 transition-colors"
                        />
                    </Tabs>
                </Box>

                {/* По описанию */}
                <TabPanel value={activeTab} index={0}>
                    <TextField
                        multiline
                        rows={5}
                        fullWidth
                        label="Описание для генерации"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        placeholder="Например: 'маркетплейс для разных товаров' или 'система с двумя микросервисами и базой данных, которая взаимодействует с CRM'"
                        className="mt-6"
                        InputProps={{
                            className: "rounded-lg"
                        }}
                    />
                </TabPanel>

                {/* Вручную */}
                <TabPanel value={activeTab} index={1}>
                    <div className="space-y-6">
                        {/* Выбор режима */}
                        <div className="flex gap-4 p-4 bg-gray-50 rounded-lg">
                            <button
                                onClick={() => setManualMode('semi')}
                                className={`flex-1 p-3 rounded-lg transition-colors ${
                                    manualMode === 'semi'
                                        ? 'bg-white border-2 border-blue-500 shadow-sm'
                                        : 'bg-white border border-gray-200 hover:border-blue-300'
                                }`}
                            >
                                <div className="flex items-center gap-2 mb-2">
                                    <Wand2 className="w-5 h-5 text-blue-500" />
                                    <span className="font-medium">Полуавтоматический режим</span>
                                </div>
                                <p className="text-sm text-gray-600">
                                    Выберите тип компонента и опишите его функционал - AI поможет с конфигурацией
                                </p>
                            </button>

                            <button
                                onClick={() => setManualMode('expert')}
                                className={`flex-1 p-3 rounded-lg transition-colors ${
                                    manualMode === 'expert'
                                        ? 'bg-white border-2 border-purple-500 shadow-sm'
                                        : 'bg-white border border-gray-200 hover:border-blue-300'
                                }`}
                            >
                                <div className="flex items-center gap-2 mb-2">
                                    <Settings2 className="w-5 h-5 text-purple-500" />
                                    <span className="font-medium">Экспертный режим</span>
                                </div>
                                <p className="text-sm text-gray-600">
                                    Полный контроль над конфигурацией компонента для опытных пользователей
                                </p>
                            </button>
                        </div>

                        {/* Контент в зависимости от режима */}
                        {manualMode === 'semi' ? (
                            <div className="space-y-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <Typography variant="subtitle2" className="mb-2">
                                            Тип компонента
                                        </Typography>
                                        <Select
                                            fullWidth
                                            value={componentConfig.type || ''}
                                            onChange={(e) => handleTypeChange(e.target.value)}
                                        >
                                            {componentTypes
                                                .filter(type => ALLOWED_COMPONENTS[type.name])
                                                .map((type) => (
                                                    <MenuItem
                                                        key={type.id}
                                                        value={type.id}
                                                        className="py-2"
                                                    >
                                                        <div>
                                                            <div className="font-medium">{type.name}</div>
                                                            <div className="text-xs text-gray-500 mt-1">
                                                                {ALLOWED_COMPONENTS[type.name].description}
                                                            </div>
                                                        </div>
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </div>

                                    {componentConfig.type && getSubtypes(componentConfig.type).length > 0 && (
                                        <div>
                                            <Typography variant="subtitle2" className="mb-2">
                                                Тип базы данных
                                            </Typography>
                                            <Select
                                                fullWidth
                                                value={componentConfig.subtype || ''}
                                                onChange={(e) => setComponentConfig({
                                                    ...componentConfig,
                                                    subtype: e.target.value
                                                })}
                                            >
                                                {getSubtypes(componentConfig.type).map((subtype) => (
                                                    <MenuItem key={subtype.id} value={subtype.id}>
                                                        {subtype.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <Typography variant="subtitle2" className="mb-2">
                                        Название компонента
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={componentConfig.systemName}
                                        onChange={(e) => {
                                            const newName = e.target.value.toLowerCase();
                                            if (newName === '' || validateSystemName(newName)) {
                                                setComponentConfig({
                                                    ...componentConfig,
                                                    systemName: newName
                                                });
                                            }
                                        }}
                                        error={componentConfig.systemName !== '' && !validateSystemName(componentConfig.systemName)}
                                        helperText={componentConfig.systemName !== '' && !validateSystemName(componentConfig.systemName)
                                            ? "Только латиница, цифры и дефис, максимум 20 символов"
                                            : ""}
                                        placeholder="Например: auth-service"
                                    />
                                </div>

                                <TextField
                                    multiline
                                    rows={4}
                                    fullWidth
                                    label="Описание компонента"
                                    value={componentConfig.prompt}
                                    onChange={(e) => setComponentConfig({
                                        ...componentConfig,
                                        prompt: e.target.value
                                    })}
                                    placeholder="Опишите нужные вам параметры компонента..."
                                />

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        if (!componentConfig.type || !componentConfig.prompt) {
                                            setError('Заполните все поля компонента');
                                            return;
                                        }
                                        setSelectedComponents([...selectedComponents, {...componentConfig}]);
                                        // Сброс формы
                                        setComponentConfig({
                                            type: null,
                                            subtype: null,
                                            prompt: ''
                                        });
                                    }}
                                    className="mt-4"
                                >
                                    Добавить компонент
                                </Button>

                                {/* Список выбранных компонентов */}
                                {selectedComponents.length > 0 && (
                                    <div className="mt-6 border rounded-lg p-4">
                                        <Typography variant="subtitle2" className="mb-3">
                                            Выбранные компоненты ({selectedComponents.length})
                                        </Typography>
                                        <div className="space-y-2">
                                            {selectedComponents.map((component, index) => (
                                                <div key={index}
                                                     className="flex justify-between items-start p-3 border rounded-lg">
                                                    <div>
                                                        <div className="font-medium">
                                                            {componentTypes.find(t => t.id === component.type)?.name}
                                                            {component.subtype && ` (${component.subtype})`}
                                                        </div>
                                                        <div className="text-sm text-gray-600 mt-1">
                                                            {component.prompt}
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={() => setSelectedComponents(
                                                            selectedComponents.filter((_, i) => i !== index)
                                                        )}
                                                        className="text-red-500 hover:text-red-700"
                                                    >
                                                        <Trash2 className="w-4 h-4"/>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <Alert severity="info" className="mb-4">
                                    Экспертный режим конфигурации находится в разработке
                                </Alert>
                            </div>
                        )}
                    </div>
                </TabPanel>

                {/* Из шаблона */}
                <TabPanel value={activeTab} index={2}>
                    <Typography variant="body2" color="text.secondary" className="mb-4 mt-6">
                        Выберите готовый шаблон системы
                    </Typography>
                    {isLoadingTemplates ? (
                        <div className="flex justify-center items-center py-8">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                        </div>
                    ) : templates.length > 0 ? (
                        <div className="grid grid-cols-1 gap-4">
                            {templates.map((template) => (
                                <div
                                    key={template.id}
                                    className={`p-4 rounded-lg border transition-all cursor-pointer
                        ${selectedTemplate === template.id
                                        ? 'border-blue-500 bg-blue-50'
                                        : 'border-gray-200 hover:border-blue-300'}`}
                                    onClick={() => setSelectedTemplate(template.id)}
                                >
                                <div className="flex justify-between items-start">
                                        <div>
                                            <h3 className="font-medium text-gray-900">
                                                {template.name}
                                            </h3>
                                            <p className="text-sm text-gray-500 mt-1">
                                                Создано: {new Date(template.created_at).toLocaleDateString()}
                                            </p>
                                        </div>
                                        <div className="flex items-center gap-2">
                            <span className="text-sm text-gray-500">
                                {template.components_count} компонентов
                            </span>
                                            <div className={`px-2 py-1 rounded text-xs 
                                ${template.status === 'ready'
                                                ? 'bg-green-100 text-green-800'
                                                : 'bg-amber-100 text-amber-800'}`}>
                                                {template.status === 'ready' ? 'Готов' : 'Создаётся'}
                                            </div>
                                        </div>
                                    </div>
                                    {template.sandbox_name && (
                                        <p className="text-sm text-gray-500 mt-2">
                                            На основе: {template.sandbox_name}
                                        </p>
                                    )}
                                    {template.created_by && (
                                        <p className="text-sm text-gray-500">
                                            Автор: {template.created_by.username}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Alert severity="info" className="rounded-lg border border-blue-100">
                            Нет доступных шаблонов
                        </Alert>
                    )}
                </TabPanel>

                {/* Мне повезёт */}
                <TabPanel value={activeTab} index={3}>
                    <div className="mt-6 bg-gradient-to-br from-amber-50 to-purple-50 p-6 rounded-lg border border-amber-100">
                        <div className="flex items-center gap-4 mb-4">
                            <Wand2 className="w-8 h-8 text-amber-500 animate-pulse" />
                            <div>
                                <Typography variant="h6" className="text-gray-800 font-medium">
                                    Магия искусственного интеллекта
                                </Typography>
                                <Typography variant="body2" className="text-gray-600">
                                    AI создаст для вас уникальную систему!
                                </Typography>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </DialogContent>

            <DialogActions className="px-6 pb-4">
                <Button
                    onClick={onClose}
                    className="text-gray-600 hover:text-gray-800"
                >
                    Отмена
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={activeTab === 0 && !prompt.trim()}
                    className="bg-blue-500 hover:bg-blue-600 text-white rounded-lg px-6"
                >
                    Создать
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateSandboxDialog;