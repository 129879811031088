import React, { useState, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, IconButton, TextField } from '@mui/material';
import { Edit2, Save, X, Eye, FileText } from 'lucide-react';
import { AuthContext } from '../../App';
import { updateLesson } from '../../services/apiService';
import SimpleToast from '../ui/toast';

const MarkdownContent = ({ content }) => (
    <div className="markdown-content bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100">
        <ReactMarkdown
            components={{
                // Заголовки
                h1: ({ children }) => (
                    <h1 className="text-4xl font-bold mb-6 mt-8">
                        {children}
                    </h1>
                ),
                h2: ({ children }) => (
                    <h2 className="text-2xl font-bold mb-4 mt-8 pb-2 border-b border-gray-200 dark:border-gray-700">
                        {children}
                    </h2>
                ),
                h3: ({ children }) => (
                    <h3 className="text-xl font-bold mb-4 mt-6">
                        {children}
                    </h3>
                ),
                // Параграфы
                p: ({ children }) => (
                    <p className="mb-4 text-base leading-7">
                        {children}
                    </p>
                ),
                // Списки
                ul: ({ children }) => (
                    <ul className="mb-6 pl-6 space-y-2 list-disc">
                        {children}
                    </ul>
                ),
                ol: ({ children }) => (
                    <ol className="mb-6 pl-6 space-y-2 list-decimal">
                        {children}
                    </ol>
                ),
                li: ({ children }) => (
                    <li className="leading-7">
                        {children}
                    </li>
                ),
                // Блоки кода
                code: ({ inline, children }) => (
                    inline ? (
                        <code className="bg-gray-100 dark:bg-gray-800 rounded px-1.5 py-0.5 text-sm font-mono">
                            {children}
                        </code>
                    ) : (
                        <pre className="bg-gray-100 dark:bg-gray-800 rounded-lg p-4 mb-4 overflow-x-auto">
                            <code className="block text-sm font-mono">
                                {children}
                            </code>
                        </pre>
                    )
                ),
                // Цитаты
                blockquote: ({ children }) => (
                    <blockquote className="border-l-4 border-gray-200 dark:border-gray-700 pl-4 mb-4 italic">
                        {children}
                    </blockquote>
                ),
                // Ссылки
                a: ({ children, href }) => (
                    <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 underline"
                    >
                        {children}
                    </a>
                ),
                // Таблицы
                table: ({ children }) => (
                    <div className="overflow-x-auto mb-6">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                            {children}
                        </table>
                    </div>
                ),
                th: ({ children }) => (
                    <th className="px-4 py-3 text-left text-sm font-medium bg-gray-50 dark:bg-gray-800">
                        {children}
                    </th>
                ),
                td: ({ children }) => (
                    <td className="px-4 py-3 text-sm border-b border-gray-200 dark:border-gray-700">
                        {children}
                    </td>
                )
            }}
        >
            {content}
        </ReactMarkdown>
    </div>
);

const EditableTextContent = ({
                                 content,
                                 lessonId,
                                 mentors = [],
                                 onContentUpdate
                             }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editableContent, setEditableContent] = useState(content);
    const [showPreview, setShowPreview] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { userInfo, token } = useContext(AuthContext);
    const [toast, setToast] = useState({ show: false, message: '', type: 'success' });

    const hasMentorAccess = mentors.includes(userInfo?.id);

    const showToast = (message, type = 'success') => {
        setToast({ show: true, message, type });
    };

    const handleSave = async () => {
        if (!editableContent.trim()) {
            showToast('Содержимое урока не может быть пустым', 'error');
            return;
        }

        setIsSaving(true);
        try {
            const updatedLesson = await updateLesson(token, lessonId, {
                content: [
                    {
                        type: 'text',
                        data: editableContent
                    }
                ]
            });

            setIsEditing(false);
            if (onContentUpdate) {
                onContentUpdate(updatedLesson.content);
            }
            showToast('Урок успешно сохранен', 'success');
        } catch (error) {
            console.error('Error saving lesson:', error);
            showToast('Ошибка при сохранении урока', 'error');
        } finally {
            setIsSaving(false);
        }
    };

    const EditToolbar = () => (
        <div className="flex items-center gap-2 mb-4 p-2 rounded-lg bg-gray-800/50">
            <IconButton
                onClick={() => setShowPreview(!showPreview)}
                className="text-gray-400 hover:text-gray-200"
                size="small"
            >
                {showPreview ? <FileText className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
            </IconButton>
            <div className="flex-grow text-sm text-gray-400">
                {showPreview ? 'Режим редактирования' : 'Предпросмотр'}
            </div>
            <div className="flex gap-2">
                <Button
                    startIcon={<Save className="w-4 h-4" />}
                    onClick={handleSave}
                    disabled={isSaving}
                    className="text-sm"
                    sx={{
                        background: 'linear-gradient(45deg, #FF6B6B, #FF8E53)',
                        '&:hover': {
                            background: 'linear-gradient(45deg, #FF8E53, #FF6B6B)',
                        }
                    }}
                >
                    {isSaving ? 'Сохранение...' : 'Сохранить'}
                </Button>
                <IconButton
                    onClick={() => {
                        setEditableContent(content);
                        setIsEditing(false);
                    }}
                    className="text-gray-400 hover:text-gray-200"
                    size="small"
                >
                    <X className="w-5 h-5" />
                </IconButton>
            </div>
        </div>
    );

    const renderContent = () => {
        if (!isEditing) {
            return (
                <div className="relative group">
                    {hasMentorAccess && (
                        <IconButton
                            onClick={() => setIsEditing(true)}
                            className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                            sx={{
                                backgroundColor: 'rgba(0,0,0,0.3)',
                                '&:hover': {
                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                }
                            }}
                        >
                            <Edit2 className="w-4 h-4 text-black" />
                        </IconButton>
                    )}
                    <div className="p-8">
                        <MarkdownContent content={content} />
                    </div>
                </div>
            );
        }

        return (
            <div className="p-8">
                <EditToolbar />
                {showPreview ? (
                    <div className="p-4 border border-gray-700 rounded-lg">
                        <MarkdownContent content={editableContent} />
                    </div>
                ) : (
                    <TextField
                        multiline
                        fullWidth
                        value={editableContent}
                        onChange={(e) => setEditableContent(e.target.value)}
                        minRows={10}
                        sx={{
                            '& .MuiInputBase-input': {
                                color: 'text.primary', // Более контрастный цвет
                                fontFamily: 'monospace',
                                fontSize: '0.9rem',
                                lineHeight: '1.5',
                                backgroundColor: '#fff', // Белый фон
                            },
                            '& .MuiInputBase-root': {
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                padding: '16px',
                                '&:hover': {
                                    backgroundColor: '#fff',
                                },
                                '&.Mui-focused': {
                                    backgroundColor: '#fff',
                                }
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(255,255,255,0.1)',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(255,255,255,0.2)',
                            },
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#FF6B6B',
                            }
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <div className="bg-white dark:bg-gray-900 rounded-lg shadow-sm">
            {renderContent()}
            {toast.show && (
                <SimpleToast
                    message={toast.message}
                    type={toast.type}
                    onClose={() => setToast({ show: false, message: '', type: 'success' })}
                />
            )}
        </div>
    );
};

export default EditableTextContent;