import React, { useState, useEffect, useCallback } from 'react';
import useImage from 'use-image';
import InfoTab from './tabs/InfoTab';
import RestTab from './tabs/RestTab';
import GraphQLTab from './tabs/GraphQLTab';
import SQLTab from './tabs/SQLTab';
import AssignmentTab from './tabs/AssignmentTab';

// Инструменты
const getTools = (hasAssignment) => [
  { name: 'Информация о компоненте', icon: '/icons/info.png', width: 400 },
  { name: 'Выполнить REST запрос', icon: '/icons/rest.png', width: 900 },
  { name: 'Выполнить GraphQL запрос', icon: '/icons/graphql.png', width: 900 },
  { name: 'Выполнить SQL запрос', icon: '/icons/sql.png', width: 900 },
  ...(hasAssignment ? [{ name: 'Задание', icon: '/icons/assignment.png', width: 400 }] : [])
];

const TOOL_PANEL_WIDTH = 48;

// Компонент для панели инструментов
const ToolPanel = ({ activeTool, handleToolChange, icons, tools }) => (
    <div
        className="flex flex-col bg-transparent overflow-y-auto justify-start"
        style={{width: TOOL_PANEL_WIDTH}}
    >
      {tools.map((tool) => (
          <div key={tool.name} className={'mt-6 mr-4'}>
            <button
                onClick={() => handleToolChange(tool)}
                className={`
                  flex items-center justify-center w-full h-12 rounded
                  ${activeTool === tool.name ? 'bg-gray-100' : 'hover:bg-gray-50'}
                  transition-colors duration-200 ease-in-out
                `}
                title={tool.name}
            >
              {icons[tool.name] ? (
                  <img
                      src={icons[tool.name].src}
                      alt={tool.name}
                      className="w-6 h-6"
                  />
              ) : (
                  '...'
              )}
            </button>
          </div>
      ))}
    </div>
);

// Компонент для панели с вкладками
const TabPanel = ({ activeTool, panelWidth, isCollapsed, activeComponent, token, assignment, assignmentStatus }) => {
  if (!activeTool) return null;

  const renderContent = () => {
    switch (activeTool) {
      case 'Информация о компоненте':
        return <InfoTab activeComponent={activeComponent}/>;
      case 'Выполнить REST запрос':
        return <RestTab activeComponent={activeComponent}/>;
      case 'Выполнить GraphQL запрос':
        return <GraphQLTab activeComponent={activeComponent}/>;
      case 'Выполнить SQL запрос':
        return <SQLTab activeComponent={activeComponent} token={token}/>;
      case 'Задание':
        return assignment ? (
            <AssignmentTab
                assignment={assignment}
                assignmentStatus={assignmentStatus}
                token={token}
            />
        ) : null;
      default:
        return null;
    }
  };

  return (
      <div
          className={`bg-transparent absolute top-0 h-full overflow-hidden ${
              isCollapsed ? '' : ''
          }`}
          style={{
            right: `${TOOL_PANEL_WIDTH}px`,
            width: isCollapsed ? '0' : `${panelWidth - TOOL_PANEL_WIDTH}px`,
            transition: 'width 0.3s ease',
          }}
      >
        <div
            className={`h-full overflow-auto ${isCollapsed ? '' : 'p-4'}`}
            style={{ display: isCollapsed ? 'none' : 'block' }}
        >
          {renderContent()}
        </div>
      </div>
  );
};

// Основной компонент панели с инструментами и вкладками
const InfoPanel = ({activeComponent, onTabChange, token, assignment, assignmentStatus }) => {
  const [activeTool, setActiveTool] = useState(null);
  const [panelWidth, setPanelWidth] = useState(TOOL_PANEL_WIDTH);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [tools, setTools] = useState([]);

  // Загружаем иконки
  const [infoIcon] = useImage('/icons/info.png');
  const [restIcon] = useImage('/icons/rest.png');
  const [graphqlIcon] = useImage('/icons/graphql.png');
  const [sqlIcon] = useImage('/icons/sql.png');
  const [assignmentIcon] = useImage('/icons/assignment.png');

  // Массив с загруженными иконками
  const icons = {
    'Информация о компоненте': infoIcon,
    'Выполнить REST запрос': restIcon,
    'Выполнить GraphQL запрос': graphqlIcon,
    'Выполнить SQL запрос': sqlIcon,
    'Задание': assignmentIcon
  };

  // Обновляем список инструментов при изменении assignment
  useEffect(() => {
    setTools(getTools(Boolean(assignment)));
    if (assignment) {
      onTabChange('Задание');
    }
  }, [assignment, onTabChange]);

  useEffect(() => {
    if (activeComponent) {
      setActiveTool('Информация о компоненте');
      setPanelWidth(TOOL_PANEL_WIDTH + 400);
      setIsCollapsed(false);
    }
  }, [activeComponent]);

  const handleToolChange = useCallback((tool) => {
    if (isCollapsed || tool.name !== activeTool) {
      setActiveTool(tool.name);
      onTabChange(tool.name);
      setPanelWidth(TOOL_PANEL_WIDTH + tool.width);
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
      setPanelWidth(TOOL_PANEL_WIDTH);
    }
  }, [isCollapsed, activeTool, onTabChange]);

  return (
      <div
          className="relative h-full overflow-visible"
          style={{ width: `${TOOL_PANEL_WIDTH}px` }}
      >
        <ToolPanel
            activeTool={activeTool}
            handleToolChange={handleToolChange}
            icons={icons}
            tools={tools}
        />
        <TabPanel
            activeTool={activeTool}
            panelWidth={panelWidth}
            isCollapsed={isCollapsed}
            activeComponent={activeComponent}
            token={token}
            assignment={assignment}
            assignmentStatus={assignmentStatus}
        />
      </div>
  );
};

export default InfoPanel;