import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthDialog from './AuthDialog';

const LandingHeader = ({ onLoginSuccess }) => {
    const { t } = useTranslation();
    const [scrolled, setScrolled] = useState(false);
    const [showAuthDialog, setShowAuthDialog] = useState(false);
    const [initialAuthMode, setInitialAuthMode] = useState('choice');


    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleAuthClick = (mode) => {
        setInitialAuthMode(mode);
        setShowAuthDialog(true);
    };

    return (
        <>
            <header className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
                scrolled ? 'bg-black/80 backdrop-blur-lg' : 'bg-transparent'
            } py-4`}>
                <div className="container mx-auto px-6 flex justify-between items-center">
                    <Link to="/" className="text-2xl font-bold text-white">
                        Dvakota
                    </Link>

                    <div className="flex items-center gap-6">
                        <Link to="/courses" className="text-white hover:text-white transition-colors">
                            {t('header.courses')}
                        </Link>
                        <button
                            onClick={() => handleAuthClick('login')}
                            className="text-white hover:text-white transition-colors"
                        >
                            {t('header.login')}
                        </button>
                        <button
                            onClick={() => handleAuthClick('register')}
                            className="bg-orange-500 hover:bg-orange-600 text-white px-6 py-2 rounded-full transition-all transform hover:scale-105"
                        >
                            {t('header.sign_up')}
                        </button>
                    </div>
                </div>
            </header>

            <AuthDialog
                open={showAuthDialog}
                onClose={() => setShowAuthDialog(false)}
                initialMode={initialAuthMode}
                onSuccess={(token) => {
                    setShowAuthDialog(false);
                    onLoginSuccess(token); // вызываем функцию из пропсов
                }}
            />
        </>
    );
};

export default LandingHeader;