import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';
import {
    createCourse,
    createModule,
    updateModule,
    deleteModule,
    createLesson,
    updateLesson,
    deleteLesson,
    fetchCourseCategories
} from '../services/apiService';
import LessonEditor from '../components/courses/LessonEditor';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    Box,
    Button,
    TextField,
    Typography,
    Paper,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Alert,
    Switch,
    FormControlLabel,
    Grid,
    Collapse,
    Card,
    CardContent,
    CircularProgress
} from '@mui/material';
import {
    Plus,
    Trash2,
    Edit2,
    GripVertical,
    ChevronDown,
    ChevronRight,
    Save,
    Image as ImageIcon
} from 'lucide-react';

const CourseConstructor = () => {
    const navigate = useNavigate();
    const { token, userInfo } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [categories, setCategories] = useState([]);

    const [course, setCourse] = useState({
        title: '',
        description: '',
        image: null,
        category: '',
        price: 0,
        is_published: false,
        is_private: false,
        has_paid_features: false
    });

    const [modules, setModules] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedModule, setSelectedModule] = useState(null);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [moduleDialog, setModuleDialog] = useState(false);
    const [lessonDialog, setLessonDialog] = useState(false);
    const [expandedModules, setExpandedModules] = useState({});
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                const categoriesData = await fetchCourseCategories();
                setCategories(categoriesData);
            } catch (err) {
                setError('Ошибка при загрузке категорий');
            } finally {
                setLoading(false);
            }
        };

        loadInitialData();
    }, [token]);

    // Проверяем права доступа
    useEffect(() => {
        if (!userInfo?.profile?.is_tutor) {
            navigate('/courses');
        }
    }, [userInfo, navigate]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setCourse({ ...course, image: file });
            setPreviewImage(URL.createObjectURL(file));
        }
    };

    const handleModuleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(modules);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // Обновляем порядок
        const updatedItems = items.map((item, index) => ({
            ...item,
            order: index + 1
        }));

        setModules(updatedItems);
    };

    const handleSaveCourse = async () => {
        try {
            setSaving(true);
            const formData = new FormData();

            // Добавляем основные поля курса
            Object.keys(course).forEach(key => {
                if (key === 'image' && !course[key]) return;
                formData.append(key, course[key]);
            });

            const savedCourse = await createCourse(token, formData);

            // Сохраняем модули
            for (const module of modules) {
                const moduleData = {
                    course: savedCourse.id,
                    title: module.title,
                    order: module.order
                };

                const savedModule = await createModule(token, moduleData);

                // Сохраняем уроки модуля
                if (module.lessons) {
                    for (const lesson of module.lessons) {
                        const lessonData = {
                            module: savedModule.id,
                            title: lesson.title,
                            content: lesson.content,
                            order: lesson.order
                        };
                        await createLesson(token, lessonData);
                    }
                }
            }

            setSuccess('Курс успешно создан');
            navigate(`/courses/${savedCourse.id}/edit`);
        } catch (err) {
            setError(err.message || 'Ошибка при сохранении курса');
        } finally {
            setSaving(false);
        }
    };

    const handleAddModule = () => {
        setSelectedModule(null);
        setModuleDialog(true);
    };

    const handleEditModule = (moduleId) => {
        const module = modules.find(m => m.id === moduleId);
        setSelectedModule(module);
        setModuleDialog(true);
    };

    const handleSaveModule = async (moduleData) => {
        try {
            if (selectedModule) {
                // Редактирование существующего модуля
                const updatedModule = await updateModule(token, selectedModule.id, moduleData);
                setModules(modules.map(m => m.id === selectedModule.id ? updatedModule : m));
            } else {
                // Создание нового модуля
                const newModule = {
                    ...moduleData,
                    order: modules.length + 1,
                    lessons: []
                };
                setModules([...modules, newModule]);
            }
            setModuleDialog(false);
        } catch (err) {
            setError('Ошибка при сохранении модуля');
        }
    };

    const handleDeleteModule = async (moduleId) => {
        try {
            await deleteModule(token, moduleId);
            setModules(modules.filter(m => m.id !== moduleId));
        } catch (err) {
            setError('Ошибка при удалении модуля');
        }
    };

    const handleAddLesson = (moduleId) => {
        setSelectedModule(moduleId);
        setSelectedLesson(null);
        setLessonDialog(true);
    };

    const handleEditLesson = (moduleId, lessonId) => {
        const module = modules.find(m => m.id === moduleId);
        const lesson = module.lessons.find(l => l.id === lessonId);
        setSelectedModule(moduleId);
        setSelectedLesson(lesson);
        setLessonDialog(true);
    };

    const handleSaveLesson = async (lessonData) => {
        try {
            const moduleIndex = modules.findIndex(m => m.id === selectedModule);
            if (moduleIndex === -1) return;

            const updatedModules = [...modules];

            if (selectedLesson) {
                // Редактирование существующего урока
                const updatedLesson = await updateLesson(token, selectedLesson.id, lessonData);
                const lessonIndex = updatedModules[moduleIndex].lessons.findIndex(
                    l => l.id === selectedLesson.id
                );
                updatedModules[moduleIndex].lessons[lessonIndex] = updatedLesson;
            } else {
                // Создание нового урока
                const newLesson = {
                    ...lessonData,
                    order: updatedModules[moduleIndex].lessons.length + 1
                };
                updatedModules[moduleIndex].lessons.push(newLesson);
            }

            setModules(updatedModules);
            setLessonDialog(false);
        } catch (err) {
            setError('Ошибка при сохранении урока');
        }
    };

    const handleDeleteLesson = async (moduleId, lessonId) => {
        try {
            await deleteLesson(token, lessonId);
            const updatedModules = modules.map(module => {
                if (module.id === moduleId) {
                    return {
                        ...module,
                        lessons: module.lessons.filter(lesson => lesson.id !== lessonId)
                    };
                }
                return module;
            });
            setModules(updatedModules);
        } catch (err) {
            setError('Ошибка при удалении урока');
        }
    };

    if (loading) {
        return (
            <Box className="flex justify-center items-center min-h-screen">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box className="container mx-auto px-4 py-8">
            {error && (
                <Alert severity="error" className="mb-4" onClose={() => setError(null)}>
                    {error}
                </Alert>
            )}

            {success && (
                <Alert severity="success" className="mb-4" onClose={() => setSuccess(null)}>
                    {success}
                </Alert>
            )}

            <Paper className="p-6 mb-8">
                <Typography variant="h5" className="mb-6 font-bold">
                    Создание нового курса
                </Typography>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            label="Название курса"
                            value={course.title}
                            onChange={(e) => setCourse({...course, title: e.target.value})}
                            className="mb-4"
                        />

                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label="Описание курса"
                            value={course.description}
                            onChange={(e) => setCourse({...course, description: e.target.value})}
                            className="mb-4"
                        />

                        <FormControl fullWidth className="mb-4">
                            <InputLabel>Категория</InputLabel>
                            <Select
                                value={course.category}
                                onChange={(e) => setCourse({...course, category: e.target.value})}
                            >
                                {categories.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Paper className="p-4 mb-4 text-center">
                            {previewImage ? (
                                <Box className="relative">
                                    <img
                                        src={previewImage}
                                        alt="Preview"
                                        className="w-full h-48 object-cover rounded"
                                    />
                                    <IconButton
                                        className="absolute top-2 right-2"
                                        onClick={() => {
                                            setPreviewImage(null);
                                            setCourse({...course, image: null});
                                        }}
                                    >
                                        <Trash2 className="w-4 h-4" />
                                    </IconButton>
                                </Box>
                            ) : (
                                <Box
                                    className="w-full h-48 border-2 border-dashed rounded flex flex-col items-center justify-center cursor-pointer"
                                    onClick={() => document.getElementById('course-image').click()}
                                >
                                    <ImageIcon className="w-8 h-8 text-gray-400 mb-2" />
                                    <Typography color="textSecondary">
                                        Выберите обложку курса
                                    </Typography>
                                </Box>
                            )}
                            <input
                                type="file"
                                id="course-image"
                                accept="image/*"
                                className="hidden"
                                onChange={handleImageChange}
                            />
                        </Paper>

                        <TextField
                            fullWidth
                            type="number"
                            label="Цена"
                            value={course.price}
                            onChange={(e) => setCourse({...course, price: e.target.value})}
                            className="mb-4"
                        />

                        <Box className="space-y-2">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={course.is_private}
                                        onChange={(e) => setCourse({...course, is_private: e.target.checked})}
                                    />
                                }
                                label="Приватный курс"
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={course.is_published}
                                        onChange={(e) => setCourse({...course, is_published: e.target.checked})}
                                    />
                                }
                                label="Опубликован"
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={course.has_paid_features}
                                        onChange={(e) => setCourse({...course, has_paid_features: e.target.checked})}
                                    />
                                }
                                label="Платные функции"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            <Paper className="p-6 mb-8">
                <Box className="flex justify-between items-center mb-6">
                    <Typography variant="h6" className="font-bold">
                        Структура курса
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<Plus />}
                        onClick={handleAddModule}
                    >
                        Добавить модуль
                    </Button>
                </Box>

                <DragDropContext onDragEnd={handleModuleDragEnd}>
                    <Droppable droppableId="modules">
                        {(provided) => (
                            <Box
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="space-y-4"
                            >
                                {modules.map((module, index) => (
                                    <Draggable
                                        key={module.id}
                                        draggableId={module.id.toString()}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <Card
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                className="mb-4"
                                            >
                                                <CardContent>
                                                    <Box className="flex items-center justify-between">
                                                        <Box className="flex items-center gap-2">
                                                            <Box {...provided.dragHandleProps} className="cursor-move">
                                                                <GripVertical className="w-5 h-5 text-gray-400" />
                                                            </Box>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    setExpandedModules({
                                                                        ...expandedModules,
                                                                        [module.id]: !expandedModules[module.id]
                                                                    })
                                                                }}
                                                            >
                                                                {expandedModules[module.id] ? (
                                                                    <ChevronDown className="w-5 h-5" />
                                                                ) : (
                                                                    <ChevronRight className="w-5 h-5" />
                                                                )}
                                                            </IconButton>
                                                            <Typography variant="h6">
                                                                {module.title}
                                                            </Typography>
                                                        </Box>
                                                        <Box className="flex items-center gap-2">
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleEditModule(module.id)}
                                                            >
                                                                <Edit2 className="w-4 h-4" />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleDeleteModule(module.id)}
                                                                className="text-red-500 hover:text-red-700"
                                                            >
                                                                <Trash2 className="w-4 h-4" />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>

                                                    <Collapse in={expandedModules[module.id]}>
                                                        <Box className="pl-8 mt-4 space-y-2">
                                                            {module.lessons?.map((lesson, lessonIndex) => (
                                                                <Paper
                                                                    key={lesson.id}
                                                                    className="p-3 border"
                                                                    variant="outlined"
                                                                >
                                                                    <Box className="flex items-center justify-between">
                                                                        <Typography>
                                                                            {lessonIndex + 1}. {lesson.title}
                                                                        </Typography>
                                                                        <Box className="flex items-center gap-1">
                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() => handleEditLesson(module.id, lesson.id)}
                                                                            >
                                                                                <Edit2 className="w-4 h-4" />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() => handleDeleteLesson(module.id, lesson.id)}
                                                                                className="text-red-500 hover:text-red-700"
                                                                            >
                                                                                <Trash2 className="w-4 h-4" />
                                                                            </IconButton>
                                                                        </Box>
                                                                    </Box>
                                                                </Paper>
                                                            ))}
                                                            <Button
                                                                startIcon={<Plus />}
                                                                onClick={() => handleAddLesson(module.id)}
                                                                variant="outlined"
                                                                fullWidth
                                                            >
                                                                Добавить урок
                                                            </Button>
                                                        </Box>
                                                    </Collapse>
                                                </CardContent>
                                            </Card>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
            </Paper>

            <Box className="flex justify-end gap-4">
                <Button
                    variant="outlined"
                    onClick={() => navigate('/courses')}
                >
                    Отмена
                </Button>
                <Button
                    variant="contained"
                    startIcon={<Save />}
                    onClick={handleSaveCourse}
                    disabled={saving}
                >
                    {saving ? 'Сохранение...' : 'Сохранить курс'}
                </Button>
            </Box>

            {/* Диалог создания/редактирования модуля */}
            <Dialog
                open={moduleDialog}
                onClose={() => setModuleDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {selectedModule ? 'Редактировать модуль' : 'Новый модуль'}
                </DialogTitle>
                <DialogContent>
                    <Box className="pt-2 space-y-4">
                        <TextField
                            fullWidth
                            label="Название модуля"
                            value={selectedModule?.title || ''}
                            onChange={(e) => setSelectedModule({
                                ...selectedModule,
                                title: e.target.value
                            })}
                        />
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            label="Описание модуля"
                            value={selectedModule?.description || ''}
                            onChange={(e) => setSelectedModule({
                                ...selectedModule,
                                description: e.target.value
                            })}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setModuleDialog(false)}>
                        Отмена
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleSaveModule(selectedModule || { title: '', description: '' })}
                    >
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Диалог создания/редактирования урока */}
            <Dialog
                open={lessonDialog}
                onClose={() => setLessonDialog(false)}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    {selectedLesson ? 'Редактировать урок' : 'Новый урок'}
                </DialogTitle>
                <DialogContent>
                    <LessonEditor
                        lesson={selectedLesson}
                        onSave={(lessonData) => {
                            handleSaveLesson(lessonData);
                            setLessonDialog(false);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLessonDialog(false)}>
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CourseConstructor;